@charset "UTF-8";
/*@import "icons";*/
.row::after, .row .row::after, .wrapper::after, .nested::after,
aside::after, .responsive-tabs-wrapper::after, .inner-wrap::after {
  clear: both;
  content: "";
  display: table; }

.hero > div, .bg-color.hero > div, .bg-color.hero.narrow > div, .map-container > iframe, .video-container > iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

/*@import "icon";*/
.row {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px; }
  .row + .row {
    padding-top: 0; }
  .row .row {
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    width: 100%;
    margin-top: 10px;
    max-width: none;
    padding: 0; }
    .row .row:first-child {
      margin-top: 0; }
    .row .row .column,
    .row .row .columns {
      padding: 0; }
  .row .column,
  .row .columns {
    float: left;
    margin: 0;
    min-height: 1px;
    padding: 10px;
    margin-top: 0;
    width: 100%; }
    @media only screen and (min-width: 48em) {
      .row .column,
      .row .columns {
        margin-left: 1.66667%; } }
    @media only screen and (min-width: 48em) {
      .row .column:first-child,
      .row .columns:first-child {
        margin-left: 0; } }
    .row .column + .column,
    .row .column + .columns,
    .row .columns + .column,
    .row .columns + .columns {
      margin-top: 10px; }
      @media only screen and (min-width: 48em) {
        .row .column + .column,
        .row .column + .columns,
        .row .columns + .column,
        .row .columns + .columns {
          margin-top: 0; } }
  @media only screen and (min-width: 48em) {
    .row .one.column,
    .row .one.columns {
      width: 6.80556%; } }
  @media only screen and (min-width: 48em) {
    .row .two.columns {
      width: 15.27778%; } }
  @media only screen and (min-width: 48em) {
    .row .three.columns {
      width: 23.75%; } }
  @media only screen and (min-width: 48em) {
    .row .four.columns {
      width: 32.22222%; } }
  @media only screen and (min-width: 48em) {
    .row .five.columns {
      width: 40.69444%; } }
  @media only screen and (min-width: 48em) {
    .row .six.columns {
      width: 49.16667%; } }
  @media only screen and (min-width: 48em) {
    .row .seven.columns {
      width: 57.63889%; } }
  @media only screen and (min-width: 48em) {
    .row .eight.columns {
      width: 66.11111%; } }
  @media only screen and (min-width: 48em) {
    .row .nine.columns {
      width: 74.58333%; } }
  @media only screen and (min-width: 48em) {
    .row .ten.columns {
      width: 83.05556%; } }
  @media only screen and (min-width: 48em) {
    .row .eleven.columns {
      width: 91.52778%; } }
  @media only screen and (min-width: 48em) {
    .row .twelve.columns {
      width: 100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-one {
      margin-left: 0% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-two {
      margin-left: 8.47222% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-three {
      margin-left: 16.94444% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-four {
      margin-left: 25.41667% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-five {
      margin-left: 33.88889% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-six {
      margin-left: 42.36111% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-seven {
      margin-left: 50.83333% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-eight {
      margin-left: 59.30556% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-nine {
      margin-left: 67.77778% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-ten {
      margin-left: 76.25% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-eleven {
      margin-left: 84.72222% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .position-twelve {
      margin-left: 93.19444% !important;
      margin-right: -100%; } }
  @media only screen and (min-width: 48em) {
    .row .one.centered {
      margin-left: 46.59722%; } }
  @media only screen and (min-width: 48em) {
    .row .two.centered {
      margin-left: 42.36111%; } }
  @media only screen and (min-width: 48em) {
    .row .three.centered {
      margin-left: 38.125%; } }
  @media only screen and (min-width: 48em) {
    .row .four.centered {
      margin-left: 33.88889%; } }
  @media only screen and (min-width: 48em) {
    .row .five.centered {
      margin-left: 29.65278%; } }
  @media only screen and (min-width: 48em) {
    .row .six.centered {
      margin-left: 25.41667%; } }
  @media only screen and (min-width: 48em) {
    .row .seven.centered {
      margin-left: 21.18056%; } }
  @media only screen and (min-width: 48em) {
    .row .eight.centered {
      margin-left: 16.94444%; } }
  @media only screen and (min-width: 48em) {
    .row .nine.centered {
      margin-left: 12.70833%; } }
  @media only screen and (min-width: 48em) {
    .row .ten.centered {
      margin-left: 8.47222%; } }
  @media only screen and (min-width: 48em) {
    .row .eleven.centered {
      margin-left: 4.23611%; } }

/*@import "fonts/icomoon";*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: "Courier New",monospace;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  margin: 0.5em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

figure {
  margin: 0; }

button, .btn-small,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, .btn-small,
input {
  line-height: 1.5; }

button, .btn-small,
select {
  text-transform: none; }

button, .btn-small,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  /*-webkit-appearance: button;*/
  cursor: pointer; }

button[disabled], [disabled].btn-small,
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

button::-moz-focus-inner, .btn-small::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

*, *::before, *::after {
  box-sizing: border-box; }
  @media print {
    *, *::before, *::after {
      background: transparent !important;
      box-shadow: none !important;
      color: #000 !important;
      text-shadow: none !important; } }

html, body {
  height: 100%;
  overflow-x: hidden; }

html {
  color: #3f3f3f;
  font: 100%/1.3 "franklin-gothic-urw", Helvetica, sans-serif;
  overflow-x: visible; }
  @media only screen and (min-width: 48em) {
    html {
      font-family: "franklin-gothic-urw", Helvetica, sans-serif;
      line-height: 1.4; } }
  @media only screen and (min-width: 64em) {
    html {
      line-height: 1.5; } }

body {
  background: white;
  color: #3f3f3f;
  margin: 0;
  hyphens: none;
  -ms-text-size-adjust: none;
  -ms-hyphens: none;
  -webkit-text-size-adjust: none;
  position: relative;
  min-width: 0;
  width: 100%; }
  body.bg-white {
    color: #3f3f3f; }

@media print {
  .nav-breadcrumbs,
  .footer-nav,
  .list-social,
  .four-up.events {
    display: none; }
  .bg-color-tile .content-wrap {
    padding: 0; }
  .bg-color-tile .p-summary {
    display: block !important; }
  .badge-heading {
    padding: 0; }
    .badge-heading p {
      max-width: inherit; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "effra", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em; }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0; }
  p + h1, ul + h1, ol + h1, p + h2, ul + h2, ol + h2, p + h3, ul + h3, ol + h3, p + h4, ul + h4, ol + h4, p + h5, ul + h5, ol + h5, p + h6, ul + h6, ol + h6 {
    margin-top: 1em; }

h1, .h1 {
  font-size: 30px;
  font-size: 1.875rem;
  margin-bottom: 0.26667em; }
  h1.page-title, .h1.page-title {
    color: #062f4e;
    margin-top: 0; }
    @media only screen and (min-width: 48em) {
      h1.page-title, .h1.page-title {
        margin-top: 0; } }
  h1 small, .h1 small {
    display: block; }
  @media only screen and (min-width: 30em) {
    h1, .h1 {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media only screen and (min-width: 48em) {
    h1, .h1 {
      font-size: 60px;
      font-size: 3.75rem; } }

h2, .h2 {
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 0.57143em; }
  .e-content h2, .e-content .h2 {
    color: #3f3f3f; }
  @media only screen and (min-width: 30em) {
    h2, .h2 {
      font-size: 32px;
      font-size: 2rem; } }
  @media only screen and (min-width: 48em) {
    h2, .h2 {
      font-size: 36px;
      font-size: 2.25rem; } }

h3, .h3 {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 0.53333em; }
  .e-content h3, .e-content .h3 {
    color: #3f3f3f;
    text-transform: uppercase; }
  @media only screen and (min-width: 30em) {
    h3, .h3 {
      font-size: 26px;
      font-size: 1.625rem; } }
  @media only screen and (min-width: 48em) {
    h3, .h3 {
      font-size: 30px;
      font-size: 1.875rem; } }

h4, .h4 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 0.66667em; }
  .e-content h4, .e-content .h4 {
    color: #062f4e;
    font-weight: 300;
    text-transform: uppercase; }
  @media only screen and (min-width: 30em) {
    h4, .h4 {
      font-size: 22px;
      font-size: 1.375rem; } }
  @media only screen and (min-width: 48em) {
    h4, .h4 {
      font-size: 24px;
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 0.72727em;
  /*&.p-name {
		color: color(greyDark);
	}*/ }
  .e-content h5, h5.section-heading, h5 span.section-heading, .e-content .h5, .h5.section-heading, .h5 span.section-heading {
    color: #993113; }

h6, .h6 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 1em; }
  .e-content h6, .e-content .h6 {
    text-transform: uppercase; }
  [role="complementary"] h6, .layout-secondary h6, [role="complementary"] .h6, .layout-secondary .h6 {
    margin-bottom: 0; }
    [role="complementary"] h6 + p, .layout-secondary h6 + p, [role="complementary"] .h6 + p, .layout-secondary .h6 + p {
      margin-top: 0.5em; }
  [role="complementary"] ul + h6, .layout-secondary ul + h6, [role="complementary"] ul + .h6, .layout-secondary ul + .h6 {
    margin-top: 1.875em; }

@media print {
  h2, h3 {
    page-break-after: avoid; } }

.page-title,
.text-color-navy {
  color: #062f4e;
  line-height: 1; }

.text-color-red {
  color: #993113; }

.section-title {
  border-top: 1px solid #d0d0d0;
  color: #993113;
  font-size: 16px;
  font-size: 1rem;
  margin:  0.625em 0em 0.9375em;
  padding-top: 1em;
  text-transform: uppercase; }
  .bg-color-dark .section-title {
    color: white;
    /*margin-top: em(15);*/ }
  .section-title:first-child {
    /*margin-top:em($columnPadding);*/ }
    [role="complementary"] .section-title:first-child {
      margin-top: 0; }
  @media only screen and (min-width: 48em) {
    .section-title {
      margin:  0.625em 0em 1.875em;
      /*&:first-child {
			margin-top:em($columnPadding);
		}*/ } }

.section-title-alt {
  color: #993113;
  font-size: 16px;
  font-size: 1rem;
  margin:  0.625em 0em 0.9375em;
  text-transform: uppercase; }
  .bg-color-dark .section-title-alt {
    color: white;
    /*margin-top: em(15);*/ }
  .section-title-alt:first-child {
    /*margin-top:em($columnPadding);*/ }
    [role="complementary"] .section-title-alt:first-child {
      margin-top: 0; }
  @media only screen and (min-width: 48em) {
    .section-title-alt {
      margin:  0.625em 0em 1.875em;
      /*&:first-child {
			margin-top:em($columnPadding);
		}*/ } }

.heading-dark {
  color: #232323; }

.heading-light {
  color: white; }

p {
  line-height: 1.3;
  margin:  1em 0em; }
  @media only screen and (min-width: 30em) {
    p {
      line-height: 1.4; } }
  @media only screen and (min-width: 60em) {
    p {
      line-height: 1.5; } }
  p:first-child {
    margin-top: 0; }

.large-body-txt {
  font-size: 130%; }

.large-txt, .search-summary {
  font-size: 22px;
  font-size: 1.375rem; }

a {
  color: #085c79;
  cursor: pointer;
  transition: color 0.3s linear;
  text-decoration: none; }
  a.p-name {
    font-weight: 700; }
  a:hover, a:active, a:focus {
    color: #0d93c1;
    /*.bg-yellow & {
			color: color(navy);
		}*/ }
  .hero a {
    font-weight: 700; }
  .bg-yellow a:not(.bg-yellow a.btn-secondary):not(.bg-yellow .bg-blue.hero a.btn-secondary):not(.bg-blue.hero .bg-yellow a.btn-secondary):not(.bg-yellow .bg-teal.hero a.btn-secondary):not(.bg-teal.hero .bg-yellow a.btn-secondary) {
    color: #104975; }
  @media print {
    a {
      text-decoration: underline; }
      a[href]::after {
        content: " (" attr(href) ")"; }
      a[href^="#"]::after, a[href^="javascript:"]::after {
        content: " "; }
      a:visited {
        text-decoration: underline; } }

@media print {
  p, h2, h3, h4 {
    orphans: 3;
    widows: 3; } }

p a {
  font-weight: 700; }

article p a {
  font-weight: 100; }

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")"; } }

::selection {
  background: #993113;
  color: white; }

hr {
  background: #d0d0d0;
  border: 0;
  border-bottom: 1px dashed #d0d0d0;
  margin:  1.25em 0em; }
  @media only screen and (min-width: 48em) {
    hr {
      margin:  2.5em 0em; } }

blockquote {
  color: #062f4e;
  font-family: "effra", Arial, sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  text-align: center;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0; }
  blockquote.small {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1; }
    @media only screen and (min-width: 40em) {
      blockquote.small {
        font-size: 26px;
        font-size: 1.625rem; } }
    @media only screen and (min-width: 48em) {
      blockquote.small {
        font-size: 32px;
        font-size: 2rem; } }
  .bg-blue blockquote {
    color: white; }
  blockquote p {
    line-height: normal;
    margin:  0em 0em 0.5em; }
  @media only screen and (min-width: 40em) {
    blockquote {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media only screen and (min-width: 48em) {
    blockquote {
      font-size: 50px;
      font-size: 3.125rem; }
      .column blockquote, .columns blockquote {
        font-size: 26px;
        font-size: 1.625rem; } }
  @media only screen and (min-width: 64em) {
    .column blockquote, .columns blockquote {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media only screen and (min-width: 76.25em) {
    .column blockquote, .columns blockquote {
      font-size: 40px;
      font-size: 2.5rem; } }
  blockquote cite {
    color: #676562;
    display: block;
    font-family: "franklin-gothic-urw", Helvetica, sans-serif;
    font-size: 16px;
    font-size: 1rem;
    font-style: normal; }
    .bg-blue blockquote cite {
      color: #d0d0d0; }
  .two-up blockquote,
  .columns blockquote {
    margin:  1em 0em;
    padding: 0; }

ul {
  list-style-image: none;
  list-style: none;
  padding-left: 1.5em; }
  ul li {
    margin:  0em 0em 0.5em 0em; }
    ul li::before {
      color: #993113;
      content: "\2022";
      display: inline-block;
      float: left;
      font-size: 1.5em;
      line-height: 1;
      margin-left: -0.72727em;
      text-align: left;
      width: 0.66667em; }

ul, ol {
  margin: 0.5em 0;
  padding: 0 0 0 1.25em; }
  ul ul, ul ol, ol ul, ol ol {
    margin-left: 0.75em;
    /*margin-top: 0;*/ }

dt {
  font-weight: 700; }

dd {
  margin:  0em 0em 1em; }

form {
  margin: 0; }

fieldset {
  border: none;
  margin:  0.75em 0em 0em;
  padding: 0; }
  fieldset:first-child, fieldset:first-of-type, fieldset.flush {
    margin-top: 0; }
  fieldset legend {
    border: none;
    font-size: 16px;
    font-size: 1rem;
    font-family: "effra", Arial, sans-serif;
    font-weight: 700; }
    @media only screen and (min-width: 60em) {
      fieldset legend {
        /*@include font-size(16);*/ } }
  fieldset ol {
    list-style-type: none;
    margin-bottom: 1.25em;
    overflow: hidden;
    padding: 0; }
  fieldset li {
    margin-bottom: 0.5em; }
    @media only screen and (min-width: 60em) {
      fieldset li {
        margin-bottom: 1em; } }
    fieldset li:first-child {
      margin-top: 0; }
    fieldset li:last-child {
      margin-bottom: 0; }
  fieldset label,
  fieldset label + select {
    display: block; }
  fieldset label.inline {
    display: inline-block;
    padding-right: 5px; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="url"] {
  height: 42px; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="url"],
select,
textarea {
  background-color: white;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  max-width: 100%;
  padding:  0.5em 0.625em;
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
  width: 100%;
  /*max-width:em(365);*/ }
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #868686; }
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder,
  input[type="tel"]:-moz-placeholder,
  input[type="url"]:-moz-placeholder,
  select:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #868686; }
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  select::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #868686; }
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  select:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #868686; }
  .bg-color input[type="text"], .bg-color
  input[type="number"], .bg-color
  input[type="password"], .bg-color
  input[type="email"], .bg-color
  input[type="search"], .bg-color
  input[type="tel"], .bg-color
  input[type="url"], .bg-color
  select, .bg-color
  textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    /* WebKit browsers */
    /* Mozilla Firefox 4 to 18 */
    /* Mozilla Firefox 19+ */
    /* Internet Explorer 10+ */
    padding:  0.5em 0em; }
    .bg-color input[type="text"]::-webkit-input-placeholder, .bg-color
    input[type="number"]::-webkit-input-placeholder, .bg-color
    input[type="password"]::-webkit-input-placeholder, .bg-color
    input[type="email"]::-webkit-input-placeholder, .bg-color
    input[type="search"]::-webkit-input-placeholder, .bg-color
    input[type="tel"]::-webkit-input-placeholder, .bg-color
    input[type="url"]::-webkit-input-placeholder, .bg-color
    select::-webkit-input-placeholder, .bg-color
    textarea::-webkit-input-placeholder {
      color: #d0d0d0; }
    .bg-color input[type="text"]:-moz-placeholder, .bg-color
    input[type="number"]:-moz-placeholder, .bg-color
    input[type="password"]:-moz-placeholder, .bg-color
    input[type="email"]:-moz-placeholder, .bg-color
    input[type="search"]:-moz-placeholder, .bg-color
    input[type="tel"]:-moz-placeholder, .bg-color
    input[type="url"]:-moz-placeholder, .bg-color
    select:-moz-placeholder, .bg-color
    textarea:-moz-placeholder {
      color: #d0d0d0; }
    .bg-color input[type="text"]::-moz-placeholder, .bg-color
    input[type="number"]::-moz-placeholder, .bg-color
    input[type="password"]::-moz-placeholder, .bg-color
    input[type="email"]::-moz-placeholder, .bg-color
    input[type="search"]::-moz-placeholder, .bg-color
    input[type="tel"]::-moz-placeholder, .bg-color
    input[type="url"]::-moz-placeholder, .bg-color
    select::-moz-placeholder, .bg-color
    textarea::-moz-placeholder {
      color: #d0d0d0; }
    .bg-color input[type="text"]:-ms-input-placeholder, .bg-color
    input[type="number"]:-ms-input-placeholder, .bg-color
    input[type="password"]:-ms-input-placeholder, .bg-color
    input[type="email"]:-ms-input-placeholder, .bg-color
    input[type="search"]:-ms-input-placeholder, .bg-color
    input[type="tel"]:-ms-input-placeholder, .bg-color
    input[type="url"]:-ms-input-placeholder, .bg-color
    select:-ms-input-placeholder, .bg-color
    textarea:-ms-input-placeholder {
      color: #d0d0d0; }
  input[type="text"].error,
  input[type="number"].error,
  input[type="password"].error,
  input[type="email"].error,
  input[type="search"].error,
  input[type="tel"].error,
  input[type="url"].error,
  select.error,
  textarea.error {
    border-color: #cc0000; }
    .bg-teal input[type="text"].error, .bg-teal
    input[type="number"].error, .bg-teal
    input[type="password"].error, .bg-teal
    input[type="email"].error, .bg-teal
    input[type="search"].error, .bg-teal
    input[type="tel"].error, .bg-teal
    input[type="url"].error, .bg-teal
    select.error, .bg-teal
    textarea.error {
      border-color: #c1e9f8; }
  input[type="text"].valid,
  input[type="number"].valid,
  input[type="password"].valid,
  input[type="email"].valid,
  input[type="search"].valid,
  input[type="tel"].valid,
  input[type="url"].valid,
  select.valid,
  textarea.valid {
    border-color: #73a531; }

abbr[title="required"], .error {
  border-bottom: none;
  color: #cc0000; }
  .bg-teal abbr[title="required"], .bg-teal .error {
    color: #c1e9f8; }

textarea {
  resize: vertical; }

input[type="radio"],
input[type="checkbox"] {
  float: left;
  height: 1px;
  margin: 0;
  opacity: 0;
  padding: 0;
  width: 1px; }
  input[type="radio"] + label,
  input[type="checkbox"] + label {
    clear: none;
    display: block;
    padding:  0.375em 0.3125em 0.25em 1.6875em; }
    input[type="radio"] + label::before,
    input[type="checkbox"] + label::before {
      display: inline-block;
      margin-left: -1.625em;
      margin-right: 0.5em;
      vertical-align: middle; }
    input[type="radio"] + label.inline,
    input[type="checkbox"] + label.inline {
      display: inline-block; }

input[type="radio"] + label::before {
  content: url("../img/css/radio-unchecked.svg"); }

input[type="radio"]:focus + label {
  color: #993113; }

input[type="radio"]:checked + label::before {
  content: url("../img/css/radio-checked.svg"); }

input[type="checkbox"] + label::before {
  content: url("../img/css/checkbox-unchecked.svg"); }

input[type="checkbox"]:focus + label {
  color: #993113; }

input[type="checkbox"]:checked + label::before {
  content: url("../img/css/checkbox-checked.svg"); }

+ button, + .btn-small {
  margin-top: 0.75em; }

.radio-list > li,
.checkbox-list > li {
  margin: 0; }

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

select::-ms-expand {
  display: none; }

select:not([multiple]), select:not(.ie9 select) {
  background: white url("../img/css/svg/chevron-down.svg") no-repeat 93% 50%;
  background-size: 1em;
  display: block;
  height: 42px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }
  select:not([multiple])::after:not([multiple]:after), select:not(.ie9 select)::after:not([multiple]:after) {
    content: url("../img/css/svg/chevron-down.svg");
    height: 1em;
    position: absolute;
    top: 0.25em;
    right: 0.5em;
    width: 1em;
    z-index: 2; }

.ie9 select {
  background: none; }

.no-js .custom-file-upload {
  display: none; }

.js input[type="file"] {
  visibility: hidden;
  width: 0px; }

/* 40 x 16 = 640 */
table {
  border-spacing: 1.25em;
  color: #676562;
  margin:  0em -1.25em;
  width: 100%; }

tr {
  border: none; }

td {
  background: white;
  position: relative;
  vertical-align: top; }
  .table--stacked td:before {
    display: none; }
  td:last-child {
    padding-bottom: .5em; }
  td::before {
    display: none; }

th, td {
  background: transparent;
  display: table-cell;
  padding:  0.5em 0em 1em;
  text-align: left; }
  tr:nth-child(2n) > th, tr:nth-child(2n) > td {
    background: white; }
  th.no-bottom, td.no-bottom {
    border-bottom: none; }
  thead th,
  tfoot th, thead td,
  tfoot td {
    background: transparent;
    border-top: 1px solid #cd522e;
    color: #232323;
    font-weight: 300; }
  tbody th, tbody td {
    border-bottom: 1px solid #d0d0d0;
    padding-top: 0; }
  tbody tr:last-child th, tbody tr:last-child td {
    border: none; }
  tfoot th, tfoot td {
    border-top: 2px solid #d0d0d0;
    font-weight: 700; }

@media (max-width: 40em) {
  html, body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%; }
  table {
    border-spacing: 0;
    margin: 1.25em 0; }
  tr {
    border-bottom: 1px solid #ececec; }
  th {
    display: none;
    /* for accessibility, use a visually hidden method here instead! Thanks, reddit!  */ }
  td {
    background: white;
    display: block;
    position: relative; }
    tr:nth-child(2n) > td {
      background: #ececec; }
    .table--stacked td:before {
      display: block;
      position: static; }
    td::before {
      content: attr(data-title) " ";
      /*  who knew you could do this? The internet, that's who.*/
      font-weight: 700;
      /* optional stuff to make it look nicer
            width: 6.5em; /*  magic number :( adjust according to your own content*/
      display: inline-block;
      left: 0.625em;
      position: absolute;
      /*  end options*/ }
  th, td {
    text-align: left; }
    tbody th,
    tfoot th, tbody td,
    tfoot td {
      border: none;
      padding:  0.75em 0em 1em;
      padding-left: 50%; }
      .table--stacked tbody th, .table--stacked
      tfoot th, .table--stacked tbody td, .table--stacked
      tfoot td {
        padding:  0.75em 0.5em 1em; }
    tfoot th, tfoot td {
      background-color: #cd522e;
      color: white;
      font-weight: 700; }
      tfoot th:first-child, tfoot td:first-child {
        padding-left: 0.5em; } }

img, object, embed {
  height: auto;
  max-width: 100%; }
  @media print {
    img, object, embed {
      max-width: 100% !important; } }

img {
  -ms-interpolation-mode: bicubic; }

figure {
  background: #ececec;
  padding: 10px; }
  figure.media {
    background: none;
    padding: 0; }

figcaption {
  font-style: italic; }
  .media figcaption {
    font-style: normal; }

picture {
  display: block; }
  picture img {
    display: block;
    width: 100%; }

.bg {
  display: none; }
  @media only screen and (min-width: 48em) {
    .bg {
      display: block; } }

.video-container + figcaption {
  margin-top: 1.25em; }

@media only screen and (min-width: 40em) {
  .media.column, .media.columns {
    border: none; } }

[class^="icon-"], [class*=" icon-"] {
  font-family: "icomoon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none; }

.banner {
  background: #993113;
  border-bottom: 5px solid #cd522e;
  padding:  0.5em 0em; }
  .banner .row {
    padding:  0em 0.625em;
    position: relative; }
    @media only screen and (min-width: 30em) {
      .banner .row {
        padding:  0em 1.25em; } }
    @media only screen and (min-width: 40em) {
      .banner .row {
        padding:  0em 1.25em; } }
  @media only screen and (min-width: 64em) {
    .banner .button-group {
      float: right; } }
  .banner .btn-unstyled {
    display: none; }
    @media only screen and (min-width: 64em) {
      .banner .btn-unstyled {
        display: inline-block; } }
  .banner .btn-login {
    display: none; }
    @media only screen and (min-width: 64em) {
      .banner .btn-login {
        display: block;
        float: right;
        margin: 2.5em 0em 0em; }
        .signed-in .banner .btn-login {
          display: inline-block;
          float: none; } }

.brand {
  background: transparent url("../img/css/svg/mta-logo.svg");
  background-repeat: no-repeat;
  background-size: 8.125em;
  display: block;
  float: left;
  min-height: 2.8125em;
  min-width: 9.375em; }
  .brand svg {
    display: none; }
  .nav-is-open .brand {
    visibility: hidden; }
  @media only screen and (min-width: 40em) {
    .brand {
      background-image: none;
      fill: white;
      height: 4.5em;
      width: 25em; }
      .brand svg {
        display: block;
        fill: white; } }
  .brand img {
    display: none;
    max-height: 2.8125em; }
    @media only screen and (min-width: 40em) {
      .brand img {
        display: block; } }

.header-group {
  margin:  0em 0em 1.25em;
  overflow: hidden;
  position: relative; }
  @media only screen and (min-width: 48em) {
    .header-group {
      margin:  0em 0em 2.5em; } }
  .header-group .page-title, .header-group h1 {
    margin-top: 0.9375em; }
    @media only screen and (min-width: 48em) {
      .header-group .page-title, .header-group h1 {
        margin-top: 0.5em; } }
  @media only screen and (min-width: 48em) {
    .header-group .btn, .header-group .btn-login {
      margin: 0;
      position: absolute;
      right: 0;
      top: 1.25em; } }

.wrapper {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0; }

.row {
  padding:  1.25em 0.625em;
  /*@include media(640) {
		padding: em(20 35);
	}*/ }
  @media only screen and (min-width: 20em) {
    .row {
      padding: 1.25em; } }
  @media only screen and (min-width: 48em) {
    .row {
      padding:  1.875em 1.25em; }
      .row.layout-two-column {
        padding-top: 3.4375em; }
      .bg-blue .row {
        padding:  3.4375em 1.25em; } }
  @media only screen and (min-width: 60em) {
    .row {
      padding:  1.875em 1.25em; } }
  @media only screen and (min-width: 76.25em) {
    .row {
      padding:  1.875em 1.25em; }
      .bg-blue .row {
        padding:  5em 1.25em; } }
  @media only screen and (min-width: 87.5em) {
    .row {
      padding:  1.875em 0em; }
      .bg-blue .row {
        padding:  5em 0em; } }
  .row .row {
    padding:  1.25em 0em; }
    @media only screen and (min-width: 48em) {
      .row .row {
        padding:  2.1875em 0em; } }

.layout-primary,
.layout-secondary,
.layout-tertiary {
  transition: all 0.3s linear;
  float: left;
  margin: 0;
  min-height: 1px;
  padding: 0;
  margin-top: 0;
  width: 100%;
  margin-bottom: 0.9375em; }
  @media only screen and (min-width: 48em) {
    .layout-primary,
    .layout-secondary,
    .layout-tertiary {
      margin-bottom: 1.875em; } }

.layout-primary {
  position: relative; }

.layout-centered {
  float: left;
  margin: 0;
  min-height: 1px;
  padding: 10px;
  margin-top: 0;
  width: 100%; }
  @media only screen and (min-width: 60em) {
    .layout-centered {
      float: left;
      margin: 0;
      min-height: 1px;
      clear: both;
      margin-left: 16.66667%;
      margin-top: 0;
      padding: 10px;
      width: 66.66667%; }
      .layout-centered:first-child {
        margin-top: 0; } }

.two-column-layout .layout-centered {
  margin-left: unset;
  width: 100%; }

/* LEFT: wide column, RIGHT: narrow column */
.layout-two-column {
  overflow: hidden;
  /*&.not-shifted {
		&.no-gutters {
			.layout-primary {
				@include media(960) {
					@include column(9, (includeGutterWidth: false));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (includeGutterWidth: false));
				}
			}
		}

		&.gutters {
			.layout-primary {
				@include media(960) {
					@include column(9);
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3);
				}
			}
		}
	}

	&.shifted {
		&.no-gutters {
			.layout-primary {
				@include media(960) {
					@include column(9, (includeGutterWidth: false, position: 4));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (includeGutterWidth: false, position: 1));
				}
			}
		}

		&.gutters {
			.layout-primary {
				@include media(960) {
					@include column(9, (position: 4));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (position: 1));
				}
			}
		}
	}*/ }
  @media only screen and (min-width: 48em) {
    .layout-two-column .layout-primary {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 59.75694%;
      margin-left: 0%;
      margin-right: -100%; }
    .layout-two-column .layout-secondary, .layout-two-column .column-narrow {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 32.22222%;
      margin-left: 67.77778%;
      margin-right: -100%; }
      .layout-two-column .layout-secondary.h-card, .layout-two-column .column-narrow.h-card {
        margin-top: -0.9375em; } }
    @media only screen and (min-width: 48em) and (min-width: 48em) {
      .layout-two-column .layout-secondary.h-card, .layout-two-column .column-narrow.h-card {
        margin-top: 4.6875em; } }
  @media only screen and (min-width: 60em) {
    .layout-two-column .layout-primary {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 57.63889%;
      margin-left: 0%;
      margin-right: -100%; }
    .layout-two-column .layout-secondary, .layout-two-column .column-narrow {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 27.98611%;
      margin-left: 72.01389%;
      margin-right: -100%; } }

.two-cols.wrapper .layout-primary, .two-cols.wrapper .layout-secondary {
  padding:  0.625em 0em; }

/* LEFT: narrow column, RIGHT: wide column */
.layout-two-column-alt .layout-primary {
  border: none; }
  @media only screen and (min-width: 48em) {
    .layout-two-column-alt .layout-primary {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 61.875%;
      margin-left: 38.125%;
      margin-right: -100%; } }
  @media only screen and (min-width: 76.25em) {
    .layout-two-column-alt .layout-primary {
      float: left;
      margin: 0;
      min-height: 1px;
      padding: 0;
      margin-top: 0;
      width: 66.11111%;
      margin-left: 33.88889%;
      margin-right: -100%; } }

@media only screen and (min-width: 48em) {
  .layout-two-column-alt .layout-secondary {
    float: left;
    margin: 0;
    min-height: 1px;
    padding: 0;
    margin-top: 0;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%; } }

@media only screen and (min-width: 76.25em) {
  .layout-two-column-alt .layout-secondary {
    float: left;
    margin: 0;
    min-height: 1px;
    padding: 0;
    margin-top: 0;
    width: 29.16667%;
    margin-left: 0%;
    margin-right: -100%; } }

/*.template-three-column {
	&.not-shifted {
		&.no-gutters {
			.layout-primary {
				@include media(960) {
					@include column(6, (includeGutterWidth: false));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (includeGutterWidth: false));
				}
			}

			.layout-tertiary {
				@include media(768) {
					@include column(3, (includeGutterWidth: false));
				}
			}
		}

		&.gutters {
			.layout-primary {
				@include media(960) {
					@include column(6);
				}
			}

			.layout-secondary {
				@include media(768) {
					@include column(3);
				}
			}

			.layout-tertiary {
				@include media(960) {
					@include column(3);
				}
			}
		}
	}

	&.shifted {
		&.no-gutters {
			.layout-primary {
				@include media(960) {
					@include column(6, (includeGutterWidth: false, position: 4));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (includeGutterWidth: false, position: 1));
				}
			}

			.layout-tertiary {
				@include media(960) {
					@include column(3, (includeGutterWidth: false, position: 10));
				}
			}
		}

		&.gutters {
			.layout-primary {
				@include media(960) {
					@include column(6, (position: 4));
				}
			}

			.layout-secondary {
				@include media(960) {
					@include column(3, (position: 1));
				}
			}

			.layout-tertiary {
				@include media(960) {
					@include column(3, (position: 10));
				}
			}
		}
	}
}*/
.nested,
aside {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin-top: 10px;
  max-width: none;
  padding: 0; }
  .nested:first-child,
  aside:first-child {
    margin-top: 0; }

.row .column, .row .columns {
  padding: 0; }

/*.two-columns {
	.column, .columns {
		+ .column, 
		+ .columns {
			@include media(768) {
			}
		}
	}
	
	+ .two-columns {
		margin-top: em(20);
	}
}*/
.contentinfo {
  background: #ececec;
  clear: both; }
  .contentinfo .row {
    padding:  0em 0.625em 1.5625em; }
    @media only screen and (min-width: 30em) {
      .contentinfo .row {
        padding:  0.625em 1.25em 1.25em; } }
    @media only screen and (min-width: 48em) {
      .contentinfo .row {
        padding-top: 1.25em; } }
    @media only screen and (min-width: 87.5em) {
      .contentinfo .row {
        padding:  1.25em 0em; } }
  .contentinfo small {
    color: #868686;
    font-size: 14px;
    font-size: 0.875rem; }
  .contentinfo .footer-nav {
    font-size: 15px;
    font-size: 0.9375rem; }
    .contentinfo .footer-nav a {
      color: #232323; }
      .contentinfo .footer-nav a.active {
        /*background-color: darken(color(greyPale),10%);
				padding: em(5);*/
        font-weight: 700; }
  .contentinfo .list-social {
    margin:  0em -0.625em 1.25em; }
    @media only screen and (min-width: 30em) {
      .contentinfo .list-social {
        margin:  0.625em 0em; } }
    @media only screen and (min-width: 40em) {
      .contentinfo .list-social {
        float: right; } }
    .contentinfo .list-social li {
      display: block; }
      @media only screen and (min-width: 30em) {
        .contentinfo .list-social li {
          display: inline-block; } }
    .contentinfo .list-social a > .text {
      display: block;
      float: left; }
      @media only screen and (min-width: 30em) {
        .contentinfo .list-social a > .text {
          height: 0;
          position: absolute;
          text-indent: -999em;
          width: 0; } }
  .contentinfo svg {
    margin-right: 0.5em; }
    @media only screen and (min-width: 30em) {
      .contentinfo svg {
        margin-right: 0; } }
  .contentinfo .column, .contentinfo .columns {
    margin-top: 0; }

.action-bar {
  background: white;
  font-size: 14px;
  font-size: 0.875rem;
  margin:  0em -1.25em 0em;
  padding: 10px; }
  @media only screen and (min-width: 30em) {
    .action-bar {
      margin:  0em -2.5em 0em;
      padding: 0 10px;
      text-align: center; } }
  @media only screen and (min-width: 40em) {
    .action-bar {
      border-top: 5px solid #d0d0d0; } }
  @media only screen and (min-width: 48em) {
    .action-bar {
      margin: 0;
      padding: 0; }
      .action-bar.is-sticky {
        border-top: none;
        left: 0;
        margin-top: 0; } }
  .action-bar ul {
    margin: 0; }
  .action-bar li {
    margin: 0; }
    @media only screen and (min-width: 30em) {
      .action-bar li {
        display: inline-block; } }
  .action-bar a {
    display: block;
    font-weight: 700;
    line-height: 1.6875em;
    margin: 0;
    padding:  0.5625em 0.625em;
    text-transform: uppercase; }
    @media only screen and (min-width: 64em) {
      .action-bar a {
        padding:  1em 1.25em; } }
    .action-bar a:hover svg, .action-bar a:active svg, .action-bar a:focus svg {
      fill: #104975; }
  .action-bar svg {
    display: inline;
    fill: #1480a6;
    float: left;
    height: 1.5em;
    margin-right: 1em;
    transition: fill 0.3s linear;
    width: 1.5em; }

@media screen and (max-device-width: 767px) {
  .sticky-wrapper {
    height: inherit; } }

@media screen and (max-device-width: 40em) {
  .sticky-wrapper {
    height: auto !important; } }

#alerts-container {
  background: #062f4e; }
  #alerts-container.is-sticky {
    padding: 0; }

.alert {
  color: white;
  margin: 0; }
  .alert p > a {
    color: white;
    text-decoration: underline; }
  .alert.alert-grey {
    background: #232323; }
    .alert.alert-grey svg.badge {
      color: #232323;
      fill: #232323; }
  .alert.alert-navy {
    background: #062f4e; }
    .alert.alert-navy svg.badge {
      color: #062f4e;
      fill: #062f4e; }
  .alert.alert-teal {
    background: #1480a6; }
    .alert.alert-teal svg.badge {
      color: #1480a6;
      fill: #1480a6; }
  .alert.alert-yellow {
    background: #e9b721; }
    .alert.alert-yellow svg.badge {
      color: #e9b721;
      fill: #e9b721; }
  .alert .ee-fieldeditor {
    position: absolute;
    bottom: 0;
    color: white;
    text-decoration: underline; }

.alert-wrap {
  background: #062f4e;
  color: white;
  margin: 0;
  text-align: center; }
  .alert-wrap .row {
    padding:  1em 3.125em 0.75em 1.25em;
    position: relative; }
  .alert-wrap .btn-small-alt {
    margin-top: 0.5em;
    margin-left: 0.5em; }
  .alert-wrap .btn-close {
    right: 0;
    margin-top: -1em;
    top: 50%; }

.alert-title {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0; }
  .wf-loading .alert-title {
    font-size: 14px;
    font-size: 0.875rem; }

.badge-primary, .badge-secondary {
  border-radius: 8px;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  margin-right: 0.5em;
  padding: 0.5em; }

.badge-primary {
  background-color: #993113;
  color: white; }

.badge-secondary {
  background-color: #ececec; }

.badge-heading {
  background-position: left top;
  background-repeat: no-repeat;
  border-bottom: 1px solid #d0d0d0;
  margin: 0;
  padding:  4.375rem 0rem 0.625rem;
  position: relative;
  text-align: center; }
  .alert .badge-heading {
    border: none;
    margin: 0; }
    .alert .badge-heading:first-of-type {
      border: none;
      margin: 0; }
  .badge-heading:first-of-type {
    border-top: 1px solid #d0d0d0;
    margin-top: 2.1875em; }
  .badge-heading:last-of-type {
    margin-bottom: 2.1875em; }
  .badge-heading h2 {
    line-height: 1;
    /*padding-left: rem(60);*/ }
  .badge-heading p {
    margin-bottom: 0;
    max-width: 32.1875rem; }
  @media only screen and (min-width: 30em) {
    .badge-heading {
      min-height: 13.125rem;
      margin: 0; }
      .badge-heading.badge-left {
        padding:  2.1875rem 1.25rem 2.1875rem 9.6875rem;
        text-align: left; }
      .badge-heading.badge-right {
        padding:  2.1875rem 9.6875rem 2.1875rem 1.25rem;
        text-align: right; }
      .badge-heading h2 {
        margin-top: 0.5em;
        padding-left: 0; } }
  @media only screen and (min-width: 40em) {
    .badge-heading {
      padding:  2.1875rem 1.25rem 2.1875rem 13.125rem; } }
  @media only screen and (min-width: 48em) {
    .badge-heading {
      min-height: 13.125rem; }
      .badge-heading.badge-right p {
        float: right;
        margin-top: 0; } }
  @media only screen and (min-width: 60em) {
    .badge-heading {
      min-height: 13.125rem; } }

.badge-left::before, .badge-left::after,
.badge-right::before,
.badge-right::after {
  background-repeat: no-repeat;
  content: '';
  height: 2.8125em;
  position: absolute;
  top: 0.625rem;
  width: 2.8125em; }

.badge-left::before,
.badge-right::before {
  background-color: black;
  background-size: 50px;
  border-radius: 100px; }

@media only screen and (min-width: 30em) {
  .badge-left::before, .badge-left::after,
  .badge-right::before,
  .badge-right::after {
    height: 8.75em;
    position: absolute;
    top: 2.1875rem;
    width: 8.75em; } }

.badge-left .xe-link,
.badge-right .xe-link {
  position: absolute;
  bottom: 0;
  width: 8.75em;
  text-align: center;
  text-decoration: underline; }

.badge-left::before, .badge-left::after {
  left: 44%; }

@media only screen and (min-width: 30em) {
  .badge-left::before, .badge-left::after {
    left: 0;
    position: absolute; } }

.badge-left .badge-large {
  left: 44%; }
  @media only screen and (min-width: 30em) {
    .badge-left .badge-large {
      left: 0; } }

.badge-left .xe-link {
  left: 0; }

.badge-right::before, .badge-right::after {
  right: 44%;
  position: absolute; }

@media only screen and (min-width: 30em) {
  .badge-right::before, .badge-right::after {
    right: 0;
    position: absolute; } }

.badge-right .badge-large {
  right: 44%; }
  @media only screen and (min-width: 30em) {
    .badge-right .badge-large {
      right: 0; } }

.badge-right .xe-link {
  right: 0; }

.benefits svg {
  fill: white; }

.badge {
  color: white;
  fill: white; }

.badge-white {
  fill: #232323; }
  .badge-white.badge {
    color: #232323;
    fill: #232323; }

i.badge, i.badge-white {
  font-size: 36px;
  font-size: 2.25rem;
  font-family: "effra", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  height: 2.75rem;
  margin-top: -0.125em;
  padding: 0;
  width: 2.75rem; }
  @media only screen and (min-width: 30em) {
    i.badge, i.badge-white {
      font-size: 90px;
      font-size: 5.625rem;
      height: 8.75rem;
      margin: 0;
      text-align: center;
      width: 8.75rem; } }

.badge-large {
  height: 2.8125em;
  padding: 0.625em;
  position: absolute;
  top: 0.625rem;
  width: 2.8125em; }
  @media only screen and (min-width: 30em) {
    .badge-large {
      height: 8.75em;
      padding: 2.5em;
      position: absolute;
      top: 2.1875rem;
      width: 8.75em; } }

.badge-yellow::before {
  background-color: #e9b721; }

.badge-red-light::before {
  background-color: #cd522e; }

.badge-blue::before {
  background-color: #104975; }

.badge-grey-medium::before {
  background-color: #868686; }

.badge-teal::before {
  background-color: #1480a6; }

.badge-white::before {
  background-color: white; }

.bg-color-tile,
.bg-color-dark {
  color: white; }
  .bg-color-tile a,
  .bg-color-dark a {
    color: white; }
    .bg-color-tile a:hover, .bg-color-tile a:active, .bg-color-tile a:focus,
    .bg-color-dark a:hover,
    .bg-color-dark a:active,
    .bg-color-dark a:focus {
      color: rgba(255, 255, 255, 0.8); }

.bg-blue {
  background-color: #104975;
  color: white; }
  .bg-blue.hero,
  .bg-blue .hero-content {
    background-color: #104975; }
    @media only screen and (min-width: 48em) {
      .bg-blue.hero,
      .bg-blue .hero-content {
        background-color: transparent; } }
  .bg-blue.hero .hero-content {
    color: white; }

.bg-red-light {
  background-color: #cd522e; }
  .bg-red-light.hero,
  .bg-red-light .hero-content {
    background-color: #cd522e; }
    @media only screen and (min-width: 48em) {
      .bg-red-light.hero,
      .bg-red-light .hero-content {
        background-color: transparent; } }

.bg-teal {
  background-color: #1480a6; }
  .bg-teal.hero,
  .bg-teal .hero-content {
    background-color: #1480a6; }
    @media only screen and (min-width: 48em) {
      .bg-teal.hero,
      .bg-teal .hero-content {
        background-color: transparent; } }
  .bg-teal.hero .hero-content {
    color: white; }

.bg-yellow {
  background-color: #e9b721; }
  .bg-yellow.hero .hero-content {
    background-color: #e9b721;
    color: #232323; }
    @media only screen and (min-width: 48em) {
      .bg-yellow.hero .hero-content {
        background-color: transparent; } }

.bg-grey-pale {
  background-color: #ececec; }
  .bg-grey-pale.hero,
  .bg-grey-pale .hero-content {
    background-color: #ececec; }
    @media only screen and (min-width: 48em) {
      .bg-grey-pale.hero,
      .bg-grey-pale .hero-content {
        background-color: transparent; } }

.bg-grey-medium {
  background-color: #868686;
  color: white; }
  .bg-grey-medium.hero,
  .bg-grey-medium .hero-content {
    background-color: #868686; }
    @media only screen and (min-width: 48em) {
      .bg-grey-medium.hero,
      .bg-grey-medium .hero-content {
        background-color: transparent; } }
  .bg-grey-medium a {
    font-size: 15px;
    font-size: 0.9375rem; }
    .bg-grey-medium a:hover, .bg-grey-medium a:active, .bg-grey-medium a:focus {
      text-decoration: underline; }

.bg-grey-dark {
  background-color: #232323;
  color: white; }
  .bg-grey-dark.hero,
  .bg-grey-dark .hero-content {
    background-color: #232323; }
    @media only screen and (min-width: 48em) {
      .bg-grey-dark.hero,
      .bg-grey-dark .hero-content {
        background-color: transparent; } }
  .bg-grey-dark a {
    font-size: 15px;
    font-size: 0.9375rem; }
    .bg-grey-dark a:hover, .bg-grey-dark a:active, .bg-grey-dark a:focus {
      text-decoration: underline; }

.bg-white {
  background-color: white; }
  .bg-white.hero,
  .bg-white .hero-content {
    background-color: white; }
    @media only screen and (min-width: 48em) {
      .bg-white.hero,
      .bg-white .hero-content {
        background-color: transparent; } }

.bg-black {
  background-color: black;
  color: white; }
  .bg-black.hero,
  .bg-black .hero-content {
    background-color: black; }
    @media only screen and (min-width: 48em) {
      .bg-black.hero,
      .bg-black .hero-content {
        background-color: transparent; } }

.bg-blue, .bg-grey-dark {
  /*a {
		color:white;
		@include transition((property: all));
		
		&:hover,
		&:active,
		&:focus {
			color: rgba(white, .8);
		}
	}	*/ }
  .bg-blue time, .bg-grey-dark time {
    color: white; }

.bg-color-light {
  color: #232323; }
  .bg-color-light ul li::before {
    color: #232323; }

.bg-color-dark {
  color: white; }
  .bg-color-dark ul li::before {
    color: #ececec; }

button, .btn-small,
.btn, .btn-login,
[type="button"],
[type="submit"], .btn-alt, .btn-small-alt, .btn-secondary-alt,
.bg-color-tile a.btn-secondary-alt,
.bg-blue a.btn-secondary-alt, .btn-secondary, .bg-blue.hero .btn-secondary, .bg-teal.hero .btn-secondary {
  border-radius: 0px;
  border-style: none;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0.35714em;
  margin-bottom: 0.57143em;
  padding:  0.71429em 2.14286em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s linear;
  -webkit-appearance: none; }
  @media only screen and (min-width: 48em) {
    button + button, .btn-small + button,
    .btn + button, .btn-login + button,
    [type="button"] + button,
    [type="submit"] + button, .btn-alt + button, .btn-small-alt + button, .btn-secondary-alt + button,
    .bg-color-tile a.btn-secondary-alt + button,
    .bg-blue a.btn-secondary-alt + button, .btn-secondary + button, .bg-blue.hero .btn-secondary + button, .bg-teal.hero .btn-secondary + button, button + .btn-small, .btn-small + .btn-small,
    .btn + .btn-small, .btn-login + .btn-small,
    [type="button"] + .btn-small,
    [type="submit"] + .btn-small, .btn-alt + .btn-small, .btn-small-alt + .btn-small, .btn-secondary-alt + .btn-small,
    .bg-color-tile a.btn-secondary-alt + .btn-small,
    .bg-blue a.btn-secondary-alt + .btn-small, .btn-secondary + .btn-small, .bg-blue.hero .btn-secondary + .btn-small, .bg-teal.hero .btn-secondary + .btn-small, button + .btn, .btn-small + .btn,
    .btn + .btn, .btn-login + .btn,
    [type="button"] + .btn,
    [type="submit"] + .btn, .btn-alt + .btn, .btn-small-alt + .btn, .btn-secondary-alt + .btn,
    .bg-color-tile a.btn-secondary-alt + .btn,
    .bg-blue a.btn-secondary-alt + .btn, .btn-secondary + .btn, .bg-blue.hero .btn-secondary + .btn, .bg-teal.hero .btn-secondary + .btn, button + .btn-login, .btn-small + .btn-login,
    .btn + .btn-login, .btn-login + .btn-login,
    [type="button"] + .btn-login,
    [type="submit"] + .btn-login, .btn-alt + .btn-login, .btn-small-alt + .btn-login, .btn-secondary-alt + .btn-login,
    .bg-color-tile a.btn-secondary-alt + .btn-login,
    .bg-blue a.btn-secondary-alt + .btn-login, .btn-secondary + .btn-login, .bg-blue.hero .btn-secondary + .btn-login, .bg-teal.hero .btn-secondary + .btn-login {
      margin-top: 0; } }
  button.btn-full-width, .btn-full-width.btn-small,
  .btn-full-width.btn, .btn-full-width.btn-login,
  .btn-full-width[type="button"],
  .btn-full-width[type="submit"], .btn-full-width.btn-alt, .btn-full-width.btn-small-alt, .btn-full-width.btn-secondary-alt,
  .bg-color-tile a.btn-full-width.btn-secondary-alt,
  .bg-blue a.btn-full-width.btn-secondary-alt, .btn-full-width.btn-secondary, .bg-blue.hero .btn-full-width.btn-secondary, .bg-teal.hero .btn-full-width.btn-secondary {
    display: block;
    margin-right: 0;
    margin-bottom: 1em; }
    button.btn-full-width:first-child, .btn-full-width.btn-small:first-child,
    .btn-full-width.btn:first-child, .btn-full-width.btn-login:first-child,
    .btn-full-width[type="button"]:first-child,
    .btn-full-width[type="submit"]:first-child, .btn-full-width.btn-alt:first-child, .btn-full-width.btn-small-alt:first-child, .btn-full-width.btn-secondary-alt:first-child, .btn-full-width.btn-secondary:first-child {
      margin-top: 0; }
  .btn-small,
  .bg-color-tile a.btn-small.btn-secondary-alt,
  .bg-blue a.btn-small.btn-secondary-alt, .bg-blue.hero .btn-small.btn-secondary, .bg-teal.hero .btn-small.btn-secondary {
    padding:  0.13333em 1.33333em; }
  button + button, .btn-small + button,
  .btn + button, .btn-login + button,
  [type="button"] + button,
  [type="submit"] + button, .btn-alt + button, .btn-small-alt + button, .btn-secondary-alt + button,
  .bg-color-tile a.btn-secondary-alt + button,
  .bg-blue a.btn-secondary-alt + button, .btn-secondary + button, .bg-blue.hero .btn-secondary + button, .bg-teal.hero .btn-secondary + button, button + .btn-small, .btn-small + .btn-small,
  .btn + .btn-small, .btn-login + .btn-small,
  [type="button"] + .btn-small,
  [type="submit"] + .btn-small, .btn-alt + .btn-small, .btn-small-alt + .btn-small, .btn-secondary-alt + .btn-small,
  .bg-color-tile a.btn-secondary-alt + .btn-small,
  .bg-blue a.btn-secondary-alt + .btn-small, .btn-secondary + .btn-small, .bg-blue.hero .btn-secondary + .btn-small, .bg-teal.hero .btn-secondary + .btn-small, button + .btn, .btn-small + .btn,
  .btn + .btn, .btn-login + .btn,
  [type="button"] + .btn,
  [type="submit"] + .btn, .btn-alt + .btn, .btn-small-alt + .btn, .btn-secondary-alt + .btn,
  .bg-color-tile a.btn-secondary-alt + .btn,
  .bg-blue a.btn-secondary-alt + .btn, .btn-secondary + .btn, .bg-blue.hero .btn-secondary + .btn, .bg-teal.hero .btn-secondary + .btn, button + .btn-login, .btn-small + .btn-login,
  .btn + .btn-login, .btn-login + .btn-login,
  [type="button"] + .btn-login,
  [type="submit"] + .btn-login, .btn-alt + .btn-login, .btn-small-alt + .btn-login, .btn-secondary-alt + .btn-login,
  .bg-color-tile a.btn-secondary-alt + .btn-login,
  .bg-blue a.btn-secondary-alt + .btn-login, .btn-secondary + .btn-login, .bg-blue.hero .btn-secondary + .btn-login, .bg-teal.hero .btn-secondary + .btn-login {
    margin-bottom: 0.5em; }

button, .btn-small,
.btn,
.btn-login,
[type="button"],
[type="submit"] {
  background-color: transparent;
  border: 1px solid #085c79;
  color: #085c79; }
  @media print {
    button, .btn-small,
    .btn,
    .btn-login,
    [type="button"],
    [type="submit"] {
      display: none; } }
  button:hover, .btn-small:hover, button:active, .btn-small:active, button:focus, .btn-small:focus,
  .btn:hover,
  .btn-login:hover,
  .btn:active,
  .btn-login:active,
  .btn:focus,
  .btn-login:focus,
  [type="button"]:hover,
  [type="button"]:active,
  [type="button"]:focus,
  [type="submit"]:hover,
  [type="submit"]:active,
  [type="submit"]:focus {
    background-color: #085c79;
    color: white; }

.btn-alt, .btn-small-alt, .btn-secondary-alt,
.bg-color-tile a.btn-secondary-alt,
.bg-blue a.btn-secondary-alt {
  background-color: #085c79;
  border: 1px solid #085c79;
  color: white; }
  @media print {
    .btn-alt, .btn-small-alt, .btn-secondary-alt,
    .bg-color-tile a.btn-secondary-alt,
    .bg-blue a.btn-secondary-alt {
      display: none; } }
  .btn-alt:hover, .btn-small-alt:hover, .btn-secondary-alt:hover,
  .bg-color-tile a.btn-secondary-alt:hover,
  .bg-blue a.btn-secondary-alt:hover, .btn-alt:active, .btn-small-alt:active, .btn-secondary-alt:active,
  .bg-color-tile a.btn-secondary-alt:active,
  .bg-blue a.btn-secondary-alt:active, .btn-alt:focus, .btn-small-alt:focus, .btn-secondary-alt:focus,
  .bg-color-tile a.btn-secondary-alt:focus,
  .bg-blue a.btn-secondary-alt:focus {
    background-color: #053849;
    border-color: #053849;
    color: white; }
  .btn-alt svg, .btn-small-alt svg, .btn-secondary-alt svg,
  .bg-color-tile a.btn-secondary-alt svg,
  .bg-blue a.btn-secondary-alt svg {
    fill: white;
    vertical-align: text-top; }

.btn-small {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  padding:  0.13333em 1.33333em;
  text-transform: none; }

.btn-small-alt {
  background-color: #1480a6;
  border-color: #1480a6;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  padding:  0.13333em 1.33333em;
  text-transform: none; }
  .btn-small-alt:hover, .btn-small-alt:active, .btn-small-alt:focus {
    background-color: #116e8f;
    border-color: #116e8f; }

.btn-secondary, .bg-blue.hero .btn-secondary, .bg-teal.hero .btn-secondary {
  background-color: transparent;
  border: 1px solid white;
  color: white; }
  @media print {
    .btn-secondary, .bg-blue.hero .btn-secondary, .bg-teal.hero .btn-secondary {
      display: none; } }
  .btn-secondary:hover, .bg-blue.hero .btn-secondary:hover, .bg-teal.hero .btn-secondary:hover, .btn-secondary:active, .bg-blue.hero .btn-secondary:active, .bg-teal.hero .btn-secondary:active, .btn-secondary:focus, .bg-blue.hero .btn-secondary:focus, .bg-teal.hero .btn-secondary:focus {
    background-color: #d0d0d0;
    border-color: #d0d0d0;
    color: #3f3f3f; }

.btn-secondary-alt,
.bg-color-tile a.btn-secondary-alt,
.bg-blue a.btn-secondary-alt {
  background-color: white;
  border-color: white;
  color: #3f3f3f !important; }
  @media print {
    .btn-secondary-alt,
    .bg-color-tile a.btn-secondary-alt,
    .bg-blue a.btn-secondary-alt {
      display: none; } }
  .btn-secondary-alt:hover, .btn-secondary-alt:active, .btn-secondary-alt:focus,
  .bg-color-tile a.btn-secondary-alt:hover,
  .bg-color-tile a.btn-secondary-alt:active,
  .bg-color-tile a.btn-secondary-alt:focus,
  .bg-blue a.btn-secondary-alt:hover,
  .bg-blue a.btn-secondary-alt:active,
  .bg-blue a.btn-secondary-alt:focus {
    background-color: rgba(255, 255, 255, 0.8);
    border-color: rgba(255, 255, 255, 0.8);
    color: #3f3f3f; }

.btn-login {
  background-color: #cd522e;
  border: none;
  color: white;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  padding:  0.26667em 1.33333em;
  text-transform: none; }
  @media print {
    .btn-login {
      display: none; } }
  .btn-login:hover, .btn-login:active, .btn-login:focus {
    background-color: #651c08; }

.btn-unstyled {
  border: none;
  color: white;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  margin-top: 0.5em;
  padding:  0.26667em 1.33333em;
  text-transform: none; }
  .btn-unstyled:hover, .btn-unstyled:active, .btn-unstyled:focus {
    background: none;
    background-color: none;
    color: white;
    text-decoration: underline; }

.btn-close {
  cursor: pointer;
  display: block;
  height: 2.75em;
  position: absolute;
  right: 1.25em;
  text-align: center;
  top: 1.5em;
  width: 2.75em; }
  @media only screen and (min-width: 48em) {
    .btn-close {
      top: 2.125em; } }
  .btn-close:after {
    color: white;
    content: "✕";
    font-size: 16px;
    font-size: 1rem;
    line-height: 1; }

.btn-news {
  float: left; }

.callout {
  background: #d0d0d0;
  margin-top: 0.625em;
  padding: 10px; }
  .callout:first-child {
    margin-top: 0; }

.divider, .divider-secondary {
  border: none;
  margin-top: 20px;
  padding-top: 20px; }

.divider {
  border-top: 1px solid #868686; }
  .divider.row {
    margin-top: 0; }

.divider-secondary {
  border-top: 1px dashed #868686; }

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  vertical-align: top; }

.fancybox-wrap {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 8020; }

.fancybox-skin {
  background: #f9f9f9;
  border-radius: 4px;
  color: #444;
  position: relative;
  text-shadow: none; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  height: 100%;
  width: 100%; }

.fancybox-image {
  max-height: 100%;
  max-width: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../img/css/fancybox-sprite.png");
  background-repeat: no-repeat;
  background-size: 44px 152px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
      background-image: url("../img/css/fancybox-sprite@2x.png"); } }

#fancybox-loading {
  background-position: 0 -108px;
  cursor: pointer;
  left: 50%;
  margin-left: -22px;
  margin-top: -22px;
  opacity: 0.8;
  position: fixed;
  top: 50%;
  z-index: 8060; }

#fancybox-loading div {
  background-image: url("../img/css/fancybox-loading.gif");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 44px;
  width: 44px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    #fancybox-loading div {
      background-image: url("../img/css/fancybox-loading@2x.gif"); } }

.fancybox-close {
  cursor: pointer;
  height: 36px;
  position: absolute;
  right: -18px;
  top: -18px;
  width: 36px;
  z-index: 8040; }

.fancybox-nav {
  background: transparent url("../img/css/fancybox-blank.gif");
  /* helps IE */
  cursor: pointer;
  height: 100%;
  position: absolute;
  text-decoration: none;
  top: 0;
  -webkit-tap-highlight-color: transparent;
  width: 40%;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  cursor: pointer;
  height: 34px;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 36px;
  z-index: 8040; }

.fancybox-prev span {
  background-position: 0 -36px;
  left: 10px; }

.fancybox-next span {
  background-position: 0 -72px;
  right: 10px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  left: -99999px;
  max-height: 99999px;
  max-width: 99999px;
  overflow: visible !important;
  position: absolute;
  top: -99999px;
  visibility: hidden; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  background: url("../img/css/fancybox-overlay.png");
  display: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 8010; }

.fancybox-overlay-fixed {
  bottom: 0;
  position: fixed;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  visibility: hidden;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  bottom: 0;
  margin-bottom: -35px;
  position: absolute;
  right: 50%;
  text-align: center;
  z-index: 8050; }

.fancybox-title-float-wrap .child {
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  display: inline-block;
  font-weight: bold;
  line-height: 24px;
  margin-right: -100%;
  padding: 2px 20px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  color: #fff;
  margin-top: 10px;
  position: relative; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 10px; }

@media only screen and (min-width: 40em) {
  .flex,
  .flex-table {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .flex-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%; }
  .flex-cell, .bg-color-tile .content-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
    .flex-cell.bg-color-tile, .bg-color-tile .bg-color-tile.content-wrap {
      flex-direction: column; }
  .flex-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  .flex-justify {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; } }

.google-map {
  margin-bottom: 1.5rem; }
  .google-map #map {
    width: 100%; }
  .google-map #infoWindow {
    padding-top: 35px; }
    .google-map #infoWindow table {
      margin: 0;
      border-spacing: unset; }
      .google-map #infoWindow table th td {
        padding-bottom: 0; }
      .google-map #infoWindow table tr {
        margin-bottom: .5rem; }
        .google-map #infoWindow table tr td {
          background: white;
          border-bottom: unset;
          padding-top: unset;
          padding-bottom: 0; }
        .google-map #infoWindow table tr td.columnLabel {
          font-weight: 700;
          padding-bottom: 0; }
      .google-map #infoWindow table td {
        padding-left: 0; }

.bg-img-small {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media only screen and (min-width: 48em) {
    .bg-img-small {
      background-image: none !important; } }

.bg-img-large p {
  color: white; }

@media only screen and (min-width: 48em) {
  .bg-img-large {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

.hero > div, .bg-color.hero > div, .bg-color.hero.narrow > div {
  position: static; }

.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  overflow: hidden;
  position: relative; }
  @media only screen and (min-width: 64em) {
    .hero {
      position: relative;
      max-height: 31.875em; }
      .hero::before {
        content: '';
        display: block;
        padding-top: 32.3125%; } }
  .hero .inner-wrap {
    padding: 80px 10px 10px;
    z-index: 2; }
    @media only screen and (min-width: 20em) {
      .hero .inner-wrap {
        padding: 100px 20px 20px; } }
    @media only screen and (min-width: 64em) {
      .hero .inner-wrap {
        bottom: 2.8125em;
        left: 0;
        padding:  0em 4.6875em;
        position: absolute;
        right: 0;
        width: 100%; } }
    @media only screen and (min-width: 76.25em) {
      .hero .inner-wrap {
        bottom: 3.4375em;
        padding:  0em 6.5625em; } }
  .hero .hero-content {
    color: white; }
    @media only screen and (min-width: 40em) {
      .hero .hero-content {
        max-width: 91.52778%; } }
    @media only screen and (min-width: 48em) {
      .hero .hero-content {
        max-width: 74.58333%; } }
    @media only screen and (min-width: 76.25em) {
      .hero .hero-content {
        max-width: 66.11111%; } }
  .hero h1 {
    color: white;
    font-size: 28px;
    font-size: 1.75rem;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 48em) {
      .hero h1 {
        font-size: 38px;
        font-size: 2.375rem; } }
    @media only screen and (min-width: 60em) {
      .hero h1 {
        font-size: 48px;
        font-size: 3rem; } }
    @media only screen and (min-width: 76.25em) {
      .hero h1 {
        font-size: 48px;
        font-size: 3rem; } }
  .hero .h4 {
    color: white;
    font-weight: 300;
    margin-bottom: 0;
    text-transform: uppercase; }
  .hero.bg-yellow .h4 {
    color: #232323; }
  .hero p {
    /*display:none;*/
    font-size: 16px;
    font-size: 1rem; }
    @media only screen and (min-width: 40em) {
      .hero p {
        /*display:block;*/
        height: auto;
        visibility: visible; } }
  .hero a:not(a.btn):not(a.btn-login) {
    /*@include font-size(16);*/
    font-weight: 700; }
  .hero img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

@media only screen and (min-width: 48em) {
  .bg-color.hero {
    position: relative;
    background-color: transparent; }
    .bg-color.hero::before {
      content: '';
      display: block;
      padding-top: 50%; } }

@media only screen and (min-width: 60em) {
  .bg-color.hero {
    position: relative; }
    .bg-color.hero::before {
      content: '';
      display: block;
      padding-top: 42.99894%; } }

@media only screen and (min-width: 64em) {
  .bg-color.hero {
    position: relative; }
    .bg-color.hero::before {
      content: '';
      display: block;
      padding-top: 39.99846%; } }

@media only screen and (min-width: 76.25em) {
  .bg-color.hero {
    position: relative; }
    .bg-color.hero::before {
      content: '';
      display: block;
      padding-top: 32.3125%; } }

@media only screen and (min-width: 87.5em) {
  .bg-color.hero {
    /*@include aspect-ratio(1600, 430, (selector: div));*/ } }

.bg-color.hero .inner-wrap {
  padding: 0; }
  @media only screen and (min-width: 40em) {
    .bg-color.hero .inner-wrap {
      position: relative;
      bottom: 0; } }
  @media only screen and (min-width: 48em) {
    .bg-color.hero .inner-wrap {
      position: absolute;
      top: 0; } }
  @media only screen and (min-width: 64em) {
    .bg-color.hero .inner-wrap {
      max-height: 26.5625em; } }

.bg-color.hero .hero-content {
  padding: 1.25em; }
  @media only screen and (min-width: 40em) {
    .bg-color.hero .hero-content {
      max-width: none; } }
  @media only screen and (min-width: 48em) {
    .bg-color.hero .hero-content {
      padding:  2.5em 1.25em 1.25em 1.25em;
      top: 0;
      max-width: 32.5em; } }
  @media only screen and (min-width: 60em) {
    .bg-color.hero .hero-content {
      padding-top: 3.4375em;
      max-width: 38.125em; } }
  @media only screen and (min-width: 64em) {
    .bg-color.hero .hero-content {
      padding-top: 2.8125em;
      max-width: 41.25em; } }
  @media only screen and (min-width: 76.25em) {
    .bg-color.hero .hero-content {
      padding-top: 3.4375em;
      max-width: 42.1875em; } }
  @media only screen and (min-width: 87.5em) {
    .bg-color.hero .hero-content {
      padding-top: 4.375em; } }

@media only screen and (min-width: 40em) {
  .bg-color.hero.narrow {
    position: relative; }
    .bg-color.hero.narrow::before {
      content: '';
      display: block;
      padding-top: 39.99846%; }
    .bg-color.hero.narrow .inner-wrap {
      position: absolute; } }

@media only screen and (min-width: 64em) {
  .bg-color.hero.narrow {
    position: relative; }
    .bg-color.hero.narrow::before {
      content: '';
      display: block;
      padding-top: 32.3125%; } }

@media only screen and (min-width: 40em) {
  .bg-color.hero.narrow .hero-content {
    bottom: 0;
    left: 0;
    padding: 1.25em;
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 20em; } }

@media only screen and (min-width: 48em) {
  .bg-color.hero.narrow .hero-content {
    padding-top: 3.4375em; } }

/* COLORED POLYGON */
@media only screen and (min-width: 40em) {
  .bg-polygon {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    .bg-polygon::before, .bg-polygon::after {
      bottom: 100%;
      content: '';
      height: 100%;
      position: absolute;
      -ms-transform-origin: 100% 100%;
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      -ms-transform: skewX(20deg);
      -webkit-transform: skewX(20deg);
      transform: skewX(20deg);
      width: 85%; }
    .bg-polygon::before {
      background-color: rgba(255, 255, 255, 0.5);
      right: 30.5%;
      top: 0; } }
    @media only screen and (min-width: 40em) and (min-width: 48em) {
      .bg-polygon::before {
        right: 20%; } }
    @media only screen and (min-width: 40em) and (min-width: 60em) {
      .bg-polygon::before {
        right: 25%; } }
    @media only screen and (min-width: 40em) and (min-width: 76.25em) {
      .bg-polygon::before {
        right: 35%; } }

@media only screen and (min-width: 40em) {
      .narrow .bg-polygon::before {
        right: 37.5%; } }
      @media only screen and (min-width: 40em) and (min-width: 48em) {
        .narrow .bg-polygon::before {
          right: 45.5%; } }
      @media only screen and (min-width: 40em) and (min-width: 60em) {
        .narrow .bg-polygon::before {
          right: 48.5%; } }
      @media only screen and (min-width: 40em) and (min-width: 64em) {
        .narrow .bg-polygon::before {
          right: 55%; } }

@media only screen and (min-width: 40em) {
    .bg-polygon::after {
      right: 32.35%;
      top: 0; } }
    @media only screen and (min-width: 40em) and (min-width: 48em) {
      .bg-polygon::after {
        right: 22%; } }
    @media only screen and (min-width: 40em) and (min-width: 60em) {
      .bg-polygon::after {
        right: 27%; } }
    @media only screen and (min-width: 40em) and (min-width: 76.25em) {
      .bg-polygon::after {
        right: 37%; } }

@media only screen and (min-width: 40em) {
      .narrow .bg-polygon::after {
        right: 39.35%; } }
      @media only screen and (min-width: 40em) and (min-width: 48em) {
        .narrow .bg-polygon::after {
          right: 47.35%; } }
      @media only screen and (min-width: 40em) and (min-width: 60em) {
        .narrow .bg-polygon::after {
          right: 50.35%; } }
      @media only screen and (min-width: 40em) and (min-width: 64em) {
        .narrow .bg-polygon::after {
          right: 57%; } }

@media only screen and (min-width: 40em) {
    .bg-blue .bg-polygon::after {
      background-color: #104975;
      background-color: rgba(16, 73, 117, 0.8); }
    .bg-grey-medium .bg-polygon::after {
      background-color: #868686;
      background-color: rgba(134, 134, 134, 0.8); }
    .bg-red-light .bg-polygon::after {
      background-color: #cd522e;
      background-color: rgba(205, 82, 46, 0.8); }
    .bg-teal .bg-polygon::after {
      background-color: #1480a6;
      background-color: rgba(20, 128, 166, 0.8); }
    .bg-yellow .bg-polygon::after {
      background-color: #e9b721;
      background-color: rgba(233, 183, 33, 0.8); } }

/* end COLORED POLYGON */
.bg-blue.hero,
.bg-blue .hero-content,
.bg-grey-medium.hero,
.bg-grey-medium .hero-content,
.bg-red-light.hero,
.bg-red-light .hero-content,
.bg-teal.hero,
.bg-teal .hero-content,
.bg-yellow.hero,
.bg-yellow .hero-content {
  background-color: #1480a6; }
  @media only screen and (min-width: 48em) {
    .bg-blue.hero,
    .bg-blue .hero-content,
    .bg-grey-medium.hero,
    .bg-grey-medium .hero-content,
    .bg-red-light.hero,
    .bg-red-light .hero-content,
    .bg-teal.hero,
    .bg-teal .hero-content,
    .bg-yellow.hero,
    .bg-yellow .hero-content {
      background-color: transparent; } }

@media only screen and (min-width: 40em) {
  .bg-blue.narrow.hero,
  .bg-blue.narrow .hero-content,
  .bg-grey-medium.narrow.hero,
  .bg-grey-medium.narrow .hero-content,
  .bg-red-light.narrow.hero,
  .bg-red-light.narrow .hero-content,
  .bg-teal.narrow.hero,
  .bg-teal.narrow .hero-content,
  .bg-yellow.narrow.hero,
  .bg-yellow.narrow .hero-content {
    background-color: transparent; } }

.bg-blue legend,
.bg-grey-medium legend,
.bg-red-light legend,
.bg-teal legend,
.bg-yellow legend {
  color: white; }

.bg-blue {
  background-color: #104975; }
  .bg-blue.hero,
  .bg-blue .hero-content {
    background-color: #104975; }
    .bg-blue.hero a,
    .bg-blue .hero-content a {
      color: white; }
      .bg-blue.hero a:hover, .bg-blue.hero a:active, .bg-blue.hero a:focus,
      .bg-blue .hero-content a:hover,
      .bg-blue .hero-content a:active,
      .bg-blue .hero-content a:focus {
        color: rgba(255, 255, 255, 0.8); }
    @media only screen and (min-width: 48em) {
      .bg-blue.hero,
      .bg-blue .hero-content {
        background-color: transparent; } }

.bg-grey-medium {
  background-color: #868686; }
  .bg-grey-medium.hero,
  .bg-grey-medium .hero-content {
    background-color: #868686; }
    .bg-grey-medium.hero a,
    .bg-grey-medium .hero-content a {
      color: white; }
      .bg-grey-medium.hero a:hover, .bg-grey-medium.hero a:active, .bg-grey-medium.hero a:focus,
      .bg-grey-medium .hero-content a:hover,
      .bg-grey-medium .hero-content a:active,
      .bg-grey-medium .hero-content a:focus {
        color: rgba(255, 255, 255, 0.8); }
    @media only screen and (min-width: 48em) {
      .bg-grey-medium.hero,
      .bg-grey-medium .hero-content {
        background-color: transparent; } }

.bg-red-light {
  background-color: #cd522e; }
  .bg-red-light.hero,
  .bg-red-light .hero-content {
    background-color: #cd522e; }
    .bg-red-light.hero a,
    .bg-red-light .hero-content a {
      color: white; }
      .bg-red-light.hero a:hover, .bg-red-light.hero a:active, .bg-red-light.hero a:focus,
      .bg-red-light .hero-content a:hover,
      .bg-red-light .hero-content a:active,
      .bg-red-light .hero-content a:focus {
        color: rgba(255, 255, 255, 0.8); }
    @media only screen and (min-width: 48em) {
      .bg-red-light.hero,
      .bg-red-light .hero-content {
        background-color: transparent; } }

.bg-teal {
  background-color: #1480a6; }
  .bg-teal.hero,
  .bg-teal .hero-content {
    background-color: #1480a6; }
    .bg-teal.hero a,
    .bg-teal .hero-content a {
      color: white; }
      .bg-teal.hero a:hover, .bg-teal.hero a:active, .bg-teal.hero a:focus,
      .bg-teal .hero-content a:hover,
      .bg-teal .hero-content a:active,
      .bg-teal .hero-content a:focus {
        color: rgba(255, 255, 255, 0.8); }
    @media only screen and (min-width: 48em) {
      .bg-teal.hero,
      .bg-teal .hero-content {
        background-color: transparent; } }

.bg-yellow {
  background-color: #e9b721; }
  .bg-yellow.hero,
  .bg-yellow .hero-content {
    background-color: #e9b721; }
    @media only screen and (min-width: 48em) {
      .bg-yellow.hero,
      .bg-yellow .hero-content {
        background-color: transparent; } }

.membership-details {
  color: white;
  font-weight: 700;
  margin-top: 1.5em; }
  @media only screen and (min-width: 48em) {
    .membership-details {
      margin-top: 2em; } }

svg {
  display: inherit;
  fill: #232323;
  height: 1em;
  width: 1em; }
  svg.privacy-lock {
    fill: #1480a6;
    margin-top: 1.5em; }
  .bg-color-dark svg,
  .list-social svg,
  .level-2 svg {
    fill: white; }
  @media only screen and (min-width: 64em) {
    .level-2 svg {
      fill: #232323; } }

.btn svg, .btn-login svg {
  fill: #085c79; }

.btn:hover svg, .btn-login:hover svg, .btn:focus svg, .btn-login:focus svg, .btn:active svg, .btn-login:active svg {
  fill: white; }

.svg-group {
  height: 0;
  width: 0; }

.format-type {
  height: 1.5em;
  margin-left: 0.5em;
  width: 1.5em; }

.icon__before {
  margin-right: 0.5em; }

.icon__after {
  margin-left: 0.5em; }

/* SOCIAL ICONS */
.list-social li {
  display: inline-block; }

.list-social a {
  color: white;
  display: block;
  line-height: 1;
  overflow: hidden;
  padding: 0.90625em;
  transition: background 0.3s linear; }
  @media only screen and (min-width: 30em) {
    .list-social a {
      /*padding:em($columnPadding * 1.5);*/ } }
  .list-social a svg {
    display: block;
    float: left; }
    @media only screen and (min-width: 30em) {
      .list-social a svg {
        display: inherit; } }

.list-social svg {
  /*margin-right:em(8);
		@include media(480){
			margin-right:0;
		}*/ }

/* end SOCIAL ICONS */
.badge-icon {
  color: white;
  text-align: center; }
  .badge-icon svg {
    display: inline-block;
    fill: white; }

.bg-facebook {
  background: #3b5998; }
  .bg-facebook:hover, .bg-facebook:active, .bg-facebook:focus {
    background: #4c70ba; }

.bg-flickr {
  background: #b3005c; }
  .bg-flickr:hover, .bg-flickr:active, .bg-flickr:focus {
    background: #ff0084; }

.bg-instagram {
  background: #e95950; }
  .bg-instagram:hover, .bg-instagram:active, .bg-instagram:focus {
    background: #d2251a; }

.bg-linkedin {
  background: #004569; }
  .bg-linkedin:hover, .bg-linkedin:active, .bg-linkedin:focus {
    background: #0077b5; }

.bg-twitter {
  background: #23b6e8; }
  .bg-twitter:hover, .bg-twitter:active, .bg-twitter:focus {
    background: #68cdef; }

.bg-vimeo {
  background: #1ab7ea; }
  .bg-vimeo:hover, .bg-vimeo:active, .bg-vimeo:focus {
    background: #0f83a8; }

.bg-youtube {
  background: #8b1615; }
  .bg-youtube:hover, .bg-youtube:active, .bg-youtube:focus {
    background: #cd201f; }

.badge-indent {
  padding:  0em 0em 0em 2.5em;
  position: relative; }
  @media only screen and (min-width: 20em) {
    .badge-indent {
      padding:  0em 0em 0em 3.125em; } }
  @media only screen and (min-width: 30em) {
    .badge-indent {
      padding:  0em 0em 0em 3.75em; } }
  @media only screen and (min-width: 48em) {
    .badge-indent {
      padding:  0em 0em 0em 5em; } }
  @media only screen and (min-width: 60em) {
    .badge-indent {
      padding:  0em 0em 0em 5em; } }
  .media .badge-indent {
    padding: 0; }
    .media .badge-indent svg {
      display: none; }
    @media only screen and (min-width: 60em) {
      .media .badge-indent {
        padding:  0em 0em 0em 5em; }
        .media .badge-indent svg {
          display: block; } }

.badge-icon {
  height: 1.5625em;
  left: 0;
  padding: 0.375em;
  position: absolute;
  top: 0.375em;
  width: 1.5625em; }
  @media only screen and (min-width: 20em) {
    .badge-icon {
      height: 2.1875em;
      padding: 0.5em;
      width: 2.1875em; } }
  @media only screen and (min-width: 30em) {
    .badge-icon {
      height: 2.8125em;
      padding: 0.75em;
      width: 2.8125em; } }

/*

.icon-arrow-down {
	@include icon(arrowDown);
}

.icon-arrow-up {
	@include icon(arrowUp);
}*/
.list-plain, .meta, .action-bar ul, .list-divider, .list-inline, .contentinfo .footer-nav, .list-inline-divider, .list-social, .nav-global ul, .event-schedule {
  padding-left: 0; }
  .list-plain li::before, .meta li::before, .action-bar ul li::before, .list-divider li::before, .list-inline li::before, .contentinfo .footer-nav li::before, .list-inline-divider li::before, .list-social li::before, .nav-global ul li::before, .event-schedule li::before {
    display: none; }

.list-divider li {
  margin-bottom: 1.25em; }
  .list-divider li:last-child {
    border: none; }

.list-inline li, .contentinfo .footer-nav li, .list-inline-divider li {
  display: inline-block;
  margin-right: 0.5em; }
  .list-inline li:first-child a, .contentinfo .footer-nav li:first-child a, .list-inline-divider li:first-child a {
    margin-left: 0; }

.list-social li {
  margin: 0; }

.list-inline-divider a {
  margin-left: 0; }

.list-inline-divider li {
  display: block; }
  @media only screen and (min-width: 30em) {
    .list-inline-divider li {
      border-left: 1px solid white;
      display: inline-block; }
      .list-inline-divider li a {
        margin-left: 0.5em; }
      .list-inline-divider li:first-child {
        border: none; }
        .list-inline-divider li:first-child a {
          margin-left: 0; } }

.list-collapsible {
  border-bottom: 1px solid #d0d0d0; }
  .list-collapsible dt {
    border-top: 1px solid #d0d0d0;
    color: #1480a6;
    cursor: pointer;
    padding:  1em 0em 0.875em 2.5em;
    position: relative;
    text-transform: uppercase; }
    @media only screen and (min-width: 30em) {
      .list-collapsible dt {
        padding-left: 3.4375em; } }
  .list-collapsible dd {
    padding-bottom: 0;
    padding-left: 2.5em; }
    @media print {
      .list-collapsible dd {
        display: block !important; } }
    @media only screen and (min-width: 30em) {
      .list-collapsible dd {
        padding-left: 3.4375em; } }

.toggle-icon svg {
  display: inline-block;
  height: 1.5em;
  margin:  0em 0.5em;
  width: 1.5em; }
  .list-collapsible .toggle-icon svg {
    /*margin-right: em(28);*/
    left: 0.5em;
    margin: 0;
    position: absolute;
    top: 1.125em; }

.toggle-icon.open,
.filter-option .toggle-icon {
  color: #232323; }
  .toggle-icon.open svg,
  .filter-option .toggle-icon svg {
    fill: #232323; }

.filter-option.closed .toggle-icon {
  color: #085c79; }
  .filter-option.closed .toggle-icon svg {
    fill: #0d93c1; }

#map {
  scroll-behavior: smooth; }

#highlighted-event {
  display: none; }

#highlighted-event-image {
  display: none; }

#form-image-upload {
  visibility: visible;
  width: auto; }

.gm-style-iw-d {
  margin-top: -50px; }
  .gm-style-iw-d p {
    font-size: .9rem; }

.gm-style .gm-style-iw-c {
  opacity: .9; }

#map-events {
  border-spacing: 0 1.5rem; }
  #map-events td {
    padding: 0.75rem 0.5rem;
    text-align: center; }
  #map-events tr td:first-child {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 2px #4b88aa solid;
    border-left: 2px #4b88aa solid;
    border-bottom: 2px #4b88aa solid;
    cursor: pointer; }
  #map-events tr td:nth-child(2) {
    text-align: center;
    font-weight: bold;
    color: #5979c7;
    border-top: 2px #4b88aa solid;
    border-bottom: 2px #4b88aa solid; }
  #map-events tr td:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 2px #4b88aa solid;
    border-right: 2px #4b88aa solid;
    border-bottom: 2px #4b88aa solid; }
  #map-events tr.past-event td:nth-child(odd) {
    background-color: #0E9B29 !important; }
    #map-events tr.past-event td:nth-child(odd):hover {
      background-color: #0e8024 !important; }
  #map-events tr.past-event td:nth-child(even) {
    background-color: #adeab8 !important; }
  #map-events tr:nth-child(odd) td:nth-child(odd) {
    background-color: #ff1f1f; }
    #map-events tr:nth-child(odd) td:nth-child(odd):hover {
      background-color: #dd2626; }
  #map-events tr:nth-child(odd) td:nth-child(even) {
    background-color: #ffc3c3; }
  #map-events tr:nth-child(even) td:nth-child(odd) {
    background-color: #1693db; }
    #map-events tr:nth-child(even) td:nth-child(odd):hover {
      background-color: #1787C8; }
  #map-events tr:nth-child(even) td:nth-child(even) {
    background-color: #a8cce0; }
  #map-events #map-event-socials {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55px; }
    #map-events #map-event-socials a {
      display: flex;
      padding: 0 0.25rem; }
      #map-events #map-event-socials a img {
        height: 51px; }

@media screen and (min-width: 770px) {
  .gm-style .gm-style-iw-c {
    opacity: 1; }
  .gm-style-iw-d {
    margin-top: -50px; }
    .gm-style-iw-d p {
      font-size: 13px; }
  #map-events {
    margin: 0em; }
    #map-events tr {
      margin-bottom: 1.5rem; }
      #map-events tr td:first-child {
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0px;
        width: 50%; }
      #map-events tr td:nth-child(2) {
        width: 25%; }
      #map-events tr td:last-child {
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px; }
  #highlighted-event {
    margin: 0em;
    border-spacing: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 2px #4b88aa solid;
    border-radius: 5px; }
    #highlighted-event #highlighted-event-local {
      background-color: #f9e694;
      padding: 0.75rem 0.5rem;
      width: 50%;
      border-radius: 5px 0px 0px 5px; }
      #highlighted-event #highlighted-event-local #highlighted-event-locale {
        color: #4a8ccc;
        margin-bottom: 0.25rem; }
      #highlighted-event #highlighted-event-local #highlighted-event-title {
        margin-bottom: 0.25rem; }
    #highlighted-event #highlighted-event-info {
      padding: 0.75rem 0.5rem;
      background-color: #fdf8e5;
      width: 25%; }
      #highlighted-event #highlighted-event-info #highlighted-event-location {
        font-weight: 600; }
    #highlighted-event #highlighted-event-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fdf8e5;
      height: 400px;
      padding: 0px; }
  #highlighted-event-image {
    display: inline;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover; } }

.text-danger {
  color: #993113; }

li.h-card {
  margin-top: 1.5em; }
  li.h-card h2 {
    margin-bottom: 0.25em; }

.h-entry.bg-color-tile {
  padding: 0; }

.h-entry + .h-entry {
  margin-top: 0.75em; }
  @media only screen and (min-width: 48em) {
    .h-entry + .h-entry {
      margin-top: 1.5em; } }

.h-entry:last-of-type {
  overflow: hidden; }

.p-name {
  word-break: initial; }

.p-name:not(span.p-name) {
  font-weight: 700; }
  .bg-yellow .p-name:not(span.p-name) {
    color: #232323; }
  a .p-name:not(span.p-name) {
    color: #085c79;
    transition: color 0.3s linear; }
    a .p-name:not(span.p-name):hover, a .p-name:not(span.p-name):active, a .p-name:not(span.p-name):focus {
      color: #0d93c1; }

.p-author {
  text-transform: uppercase; }

.p-summary {
  margin-top: 0; }
  .bg-grey-pale .p-summary {
    margin-top: -0.25em; }
  .bg-color-tile .p-summary {
    margin-top: -0.5em; }

.dt-published {
  color: #676562; }
  .search-results .dt-published {
    margin-left: 1.25em; }
  .bg-color-dark .dt-published {
    color: white; }

.bg-blue:not(.bg-color.bg-blue .bg-white) .p-author, .bg-blue:not(.bg-color.bg-blue .bg-white) .dt-published, .bg-blue:not(.bg-color.bg-blue .bg-white) .u-url:not(.u-url.btn-secondary):not(.bg-blue.hero .u-url.btn-secondary):not(.bg-teal.hero .u-url.btn-secondary) {
  color: white; }
  .bg-blue:not(.bg-color.bg-blue .bg-white) .p-author:hover, .bg-blue:not(.bg-color.bg-blue .bg-white) .p-author:active, .bg-blue:not(.bg-color.bg-blue .bg-white) .p-author:focus, .bg-blue:not(.bg-color.bg-blue .bg-white) .dt-published:hover, .bg-blue:not(.bg-color.bg-blue .bg-white) .dt-published:active, .bg-blue:not(.bg-color.bg-blue .bg-white) .dt-published:focus, .bg-blue:not(.bg-color.bg-blue .bg-white) .u-url:not(.u-url.btn-secondary):not(.bg-blue.hero .u-url.btn-secondary):not(.bg-teal.hero .u-url.btn-secondary):hover, .bg-blue:not(.bg-color.bg-blue .bg-white) .u-url:not(.u-url.btn-secondary):not(.bg-blue.hero .u-url.btn-secondary):not(.bg-teal.hero .u-url.btn-secondary):active, .bg-blue:not(.bg-color.bg-blue .bg-white) .u-url:not(.u-url.btn-secondary):not(.bg-blue.hero .u-url.btn-secondary):not(.bg-teal.hero .u-url.btn-secondary):focus {
    color: rgba(255, 255, 255, 0.8); }

.u-url {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word; }

.h-card .u-photo {
  border-radius: 50%;
  display: inline-block;
  float: left;
  max-height: 5.3125em;
  margin-right: 1em;
  max-width: 30%; }
  @media screen and (max-width: 35em) {
    .h-card .u-photo {
      shape-outside: circle(); } }

.h-event footer {
  overflow: hidden; }

@media only screen and (min-width: 30em) {
  .event-cta, .event-social {
    float: left; } }

.event-cta {
  margin-right: 1em; }

.event-social {
  margin:  -0.0625em 0em 0em; }

.nav-breadcrumbs {
  color: #3f3f3f;
  margin: 0;
  padding-left: 0; }
  .nav-breadcrumbs li {
    display: inline-block;
    margin:  0em 0.25em 0em 0em;
    padding: 0; }
    .nav-breadcrumbs li::before {
      color: #676562;
      content: "› ";
      line-height: .8;
      margin-left: 0.125em; }
    .nav-breadcrumbs li:first-child::before {
      display: none; }
    .nav-breadcrumbs li a {
      display: inline-block; }
      .nav-breadcrumbs li a:hover, .nav-breadcrumbs li a:active, .nav-breadcrumbs li a:focus {
        text-decoration: underline; }

.nav-toggle {
  border: 1px solid white;
  color: white;
  cursor: pointer;
  display: block;
  float: right;
  height: 2.75em;
  position: relative;
  width: 2.75em;
  z-index: 10; }
  .nav-toggle:hover, .nav-toggle:focus, .nav-toggle:active {
    color: white; }
  .nav-toggle:before {
    border-top: .525em double white;
    border-bottom: .19em solid white;
    color: white;
    content: "";
    font-size: 16px;
    font-size: 1rem;
    height: .825em;
    line-height: 1;
    margin:  -0.5em -0.5em 0em 0em;
    position: absolute;
    right: 50%;
    text-align: center;
    top: 50%;
    width: 1em; }
  .nav-toggle.active:before {
    border: none;
    content: "✕"; }
  @media only screen and (min-width: 64em) {
    .nav-toggle {
      display: none; } }

.nav-is-open .banner, .nav-is-open .nav-global {
  background: #062f4e; }
  @media only screen and (min-width: 64em) {
    .nav-is-open .banner, .nav-is-open .nav-global {
      background: #993113; } }

.nav-is-open .banner {
  border-color: #062f4e; }
  @media only screen and (min-width: 64em) {
    .nav-is-open .banner {
      border-color: #cd522e; } }

/* parent links */
.nav__link {
  color: #50c2ea;
  display: block;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding:  0.75em 0em;
  position: relative;
  text-transform: uppercase; }
  @media only screen and (min-width: 64em) {
    .nav__link {
      color: #3f3f3f;
      padding:  1.25em 1em; }
      .nav__link:hover, .nav__link:focus, .nav__link:active, .nav__link.active {
        background: #ececec;
        color: #3f3f3f; } }

/* GLOBAL NAVIGATION */
.nav-global {
  position: absolute;
  z-index: -1; }
  .nav-global .button-group {
    text-align: center; }
  .nav-global .btn-login {
    display: inline-block; }
  .nav-global ul {
    margin: 0; }
  @media only screen and (min-width: 64em) {
    .nav-global {
      position: static; }
      .nav-global .row {
        padding: 0; }
      .nav-global .button-group {
        display: none; }
      .nav-global .btn-login {
        display: none; }
      .nav-global li {
        display: inline-block;
        margin-bottom: 0; } }

.nav-global {
  clear: both;
  transition: all 0.3s ease-out;
  /*&.active, */ }
  .nav-global .level-2 {
    clear: both;
    visibility: hidden;
    transition: all 0.3s ease-out; }
  .js .nav-global,
  .js .nav-global .level-2 {
    max-height: 0;
    background: #062f4e;
    display: none;
    visibility: hidden; }
    @media only screen and (min-width: 64em) {
      .js .nav-global,
      .js .nav-global .level-2 {
        display: block;
        visibility: visible; } }
  .js .nav-global .level-2.active {
    display: block;
    max-height: 55em;
    visibility: visible; }
  .nav-global.active {
    display: block;
    height: 100%;
    left: 0;
    max-height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 3.75em;
    visibility: visible;
    width: 100%;
    z-index: 10; }
    @media only screen and (min-width: 64em) {
      .nav-global.active {
        height: auto;
        position: static; } }
  .nav-global li.has-subnav {
    margin: 0; }
    .nav-global li.has-subnav > a {
      padding-right: 1.25em; }
      .nav-global li.has-subnav > a::after {
        content: '+';
        display: block;
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 1.85;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0; }
      .nav-global li.has-subnav > a.nav-item-active:after {
        content: "-";
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 1.5;
        text-align: center;
        width: 14px; }
  @media only screen and (min-width: 64em) {
    .nav-global {
      border-bottom: 1px solid #ececec;
      /* li a {
			border: 0;
		}*/ }
      .nav-global .row {
        position: relative; }
        .nav-global .row > ul {
          margin: 0 0 0 -0.25em;
          border: 0; }
          .nav-global .row > ul > li {
            display: inline-block;
            position: relative; }
          .nav-global .row > ul li:hover .nav__link {
            background: #ececec; }
          .nav-global .row > ul ul {
            position: absolute;
            top: 0;
            left: 12em;
            /*width: 12em;*/ }
            .nav-global .row > ul ul.level-2 {
              top: 3.75em;
              left: 0; }
            .nav-global .row > ul ul li {
              position: relative; }
      .js .nav-global, .js .nav-global .row > ul ul {
        max-height: none;
        overflow: visible;
        background: white; }
      .js .nav-global .row > ul ul {
        background: #ececec;
        /*display: none;*/
        opacity: 0;
        visibility: hidden; }
      .js .nav-global .row > ul li:hover > ul,
      .js .nav-global .row > ul .nav__link:focus + ul {
        display: block;
        opacity: 1;
        visibility: visible;
        z-index: 10; }
      .js .nav-global .row > ul li:hover > ul {
        transition-delay: 300ms; }
      .nav-global .level-1 > li.has-subnav > a:after {
        content: ""; } }

.level-2 a {
  color: white;
  display: block;
  padding:  0.375em 0em; }
  .level-2 a:hover, .level-2 a:focus, .level-2 a:active {
    color: #50c2ea; }
  .level-2 a.active {
    color: #1589b1;
    font-weight: 700; }
    @media only screen and (min-width: 64em) {
      .level-2 a.active {
        color: black; } }

.level-2 li {
  margin: 0; }

@media only screen and (min-width: 64em) {
  .level-1 {
    position: static;
    z-index: 10; }
    .level-1 li {
      margin: 0 0.25em; }
  .level-2 {
    padding: 10px;
    min-width: 39.6875em;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2; }
    .level-2 li {
      padding: 0 10px;
      width: 100%; }
    .level-2 a {
      color: #676562;
      display: block;
      padding:  0.375em 0.5em;
      transition: all 0.3s linear; }
      .level-2 a:hover, .level-2 a:focus, .level-2 a:active {
        background: #cacaca;
        color: black; }
    .right-align .level-2 {
      left: initial !important;
      right: 0; } }

.nav-pagination {
  background: #085c79;
  margin:  1.25em 0em;
  padding: 1.25em;
  text-align: center; }
  @media only screen and (min-width: 48em) {
    .nav-pagination {
      margin:  2.5em 0em; } }
  .nav-pagination li {
    display: inline-block;
    margin: 0; }
    .nav-pagination li::before {
      color: white;
      margin:  0em 0.5em; }
    .nav-pagination li:first-child::before {
      display: none; }
  .nav-pagination a {
    color: white;
    transition: color 0.3s linear; }
    .nav-pagination a:hover, .nav-pagination a:active, .nav-pagination a:focus {
      color: rgba(255, 255, 255, 0.8); }
    .nav-pagination a.active {
      color: #e9b721;
      font-weight: 700; }

.two-up,
.two-up--md,
.three-up,
.four-up,
.five-up {
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  .two-up:first-child,
  .two-up--md:first-child,
  .three-up:first-child,
  .four-up:first-child,
  .five-up:first-child {
    margin-top: 0; }
  .two-up:nth-child(n+2),
  .two-up--md:nth-child(n+2),
  .three-up:nth-child(n+2),
  .four-up:nth-child(n+2),
  .five-up:nth-child(n+2) {
    margin-top: 1.25rem; }

@media only screen and (min-width: 40em) {
  .two-up,
  .three-up,
  .four-up,
  .five-up {
    margin-left: 4.16667%;
    margin-right: -0.25em; }
    .two-up:nth-child(n+2),
    .three-up:nth-child(n+2),
    .four-up:nth-child(n+2),
    .five-up:nth-child(n+2) {
      margin-top: 0; }
  .two-up,
  .four-up {
    text-align: left;
    width: 47.91667%; }
    .two-up:nth-child(2n+1),
    .four-up:nth-child(2n+1) {
      margin-left: 0; }
    .two-up:nth-child(n+3),
    .four-up:nth-child(n+3) {
      margin-top: 1.25rem; }
  .three-up,
  .five-up {
    text-align: left;
    margin-left: 4.16667%;
    width: 30.55556%; }
    .three-up:nth-child(3n+1),
    .five-up:nth-child(3n+1) {
      margin-left: 0; }
    .three-up:nth-child(n+4),
    .five-up:nth-child(n+4) {
      margin-top: 1.25rem; } }

@media only screen and (min-width: 64em) {
  .four-up {
    margin-left: 2.08333%;
    width: 23.4375%; }
    .four-up:nth-child(2n+1) {
      margin-left: 2.08333%; }
    .four-up:nth-child(4n+1) {
      margin-left: 0; }
    .four-up:nth-child(n+3) {
      margin-top: 0; }
    .four-up:nth-child(n+5) {
      margin-top: 1.25rem; } }

@media only screen and (min-width: 76.25em) {
  .five-up {
    margin-left: 2.08333%;
    width: 18.33333%; }
    .five-up:nth-child(2n+1), .five-up:nth-child(n+4) {
      margin-left: 2.08333%; }
    .five-up:nth-child(5n+1) {
      margin-left: 0; }
    .five-up:nth-child(n+4) {
      margin-top: 0; }
    .five-up:nth-child(n+6) {
      margin-top: 1.25rem; } }

@media only screen and (min-width: 48em) {
  .two-up--md {
    margin-left: 2.08333%;
    margin-right: -0.25em;
    text-align: left;
    width: 48.95833%; }
    .two-up--md:nth-child(2n+1) {
      margin-left: 0; }
    .two-up--md:nth-child(n+2) {
      margin-top: 0; }
    .two-up--md:nth-child(n+3) {
      margin-top: 1.25rem; } }

.responsive-tabs-wrapper {
  margin-top: 10px; }
  .responsive-tabs-wrapper:first-child {
    margin-top: 0; }

.responsive-tabs__heading {
  background: white url("../img/css/svg/chevron-down.svg") no-repeat 97% 50%;
  background-size: 1em;
  border: 1px solid white;
  border-radius: 0px;
  color: #0d93c1;
  cursor: pointer;
  display: block;
  padding: 0.5em;
  position: relative;
  /*@include icon(arrowDown, (pseudo: after)) {
		display: block;
		@include font-size(32);
		@extend %icon-config;
		position: absolute;
		right: 20px;
		top: 13px;
		width: 20px;

		@include media(print) {
			display: none;
		}
	}*/ }
  .e-content .responsive-tabs__heading {
    color: #0d93c1; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs__heading {
      display: none; } }
  @media print {
    .responsive-tabs__heading {
      background: white;
      border-bottom: 1px dashed black;
      border-radius: 0px 0px 0 0;
      color: #085c79;
      margin-bottom: 0;
      /*@include icon(arrowUp, (pseudo: after) ) {
			color: $tabActiveTextColor;
		}*/ } }

.responsive-tabs__heading--active {
  background: white url("../img/css/svg/chevron-up.svg") no-repeat 97% 50%;
  background-size: 1.125em;
  border-bottom: 1px dashed black;
  border-radius: 0px 0px 0 0;
  color: #085c79;
  margin-bottom: 0;
  /*@include icon(arrowUp, (pseudo: after) ) {
			color: $tabActiveTextColor;
		}*/ }

.responsive-tabs__heading:hover {
  background-color: white; }

.responsive-tabs__heading--active:hover {
  background-color: white; }

.responsive-tabs__panel {
  background-color: white;
  border: 1px solid white;
  border-top: none;
  color: #3f3f3f;
  margin-bottom: 0.75em;
  padding:  1em 0em; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs__panel {
      margin-bottom: 0; }
      .responsive-tabs-vertical-left .responsive-tabs__panel,
      .responsive-tabs-vertical-right .responsive-tabs__panel {
        border-top: 1px solid white;
        display: inline-block;
        min-height: 300px;
        width: 75%; }
      .responsive-tabs-vertical-left .responsive-tabs__panel {
        float: left; }
      .responsive-tabs-vertical-right .responsive-tabs__panel {
        float: right; } }
  @media print {
    .responsive-tabs__panel {
      display: block !important; } }

.responsive-tabs__panel--closed-accordion-only {
  display: none; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs__panel--closed-accordion-only {
      display: block !important; } }
  @media print {
    .responsive-tabs__panel--closed-accordion-only {
      display: block !important; } }

.responsive-tabs__list {
  display: none;
  font-size: 19px;
  font-size: 1.1875rem;
  list-style: none;
  margin: 0;
  padding: 0; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs-horizontal .responsive-tabs__list {
      border-bottom: 1px solid white;
      display: block; }
    .responsive-tabs-vertical-left .responsive-tabs__list,
    .responsive-tabs-vertical-right .responsive-tabs__list {
      border-left: 3px solid white;
      display: inline-block;
      position: relative;
      width: 25%; }
    .responsive-tabs-vertical-left .responsive-tabs__list {
      float: left;
      left: 1px;
      margin-left: -1px; }
    .responsive-tabs-vertical-right .responsive-tabs__list {
      float: right;
      margin-right: -1px;
      right: 1px; } }

.responsive-tabs__list__item {
  color: white;
  cursor: pointer; }
  .red-light-tab .responsive-tabs__list__item {
    background-color: #cd522e; }
  .mta-blue-tab .responsive-tabs__list__item {
    background-color: #0060a9; }
  .yellow-tab .responsive-tabs__list__item {
    background-color: #ebbf38; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs-horizontal .responsive-tabs__list__item {
      display: inline-block;
      margin-right: 0;
      padding: 14px 14px; }
    .responsive-tabs-vertical-left .responsive-tabs__list__item {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0;
      padding: 6px 15px;
      margin-right: 10%;
      border-left: 3px solid white; }
    .responsive-tabs-vertical-right .responsive-tabs__list__item {
      border-bottom-right-radius: 0px;
      border-top-left-radius: 0; }
    .responsive-tabs-vertical-left .responsive-tabs__list__item,
    .responsive-tabs-vertical-right .responsive-tabs__list__item {
      display: block;
      margin-bottom: 0px;
      transition: background 350ms ease; } }
  @media only screen and (min-width: 60em) {
    .responsive-tabs-horizontal .responsive-tabs__list__item {
      padding: 14px 14px;
      transition: background 350ms ease; } }
  @media only screen and (min-width: 64em) {
    .responsive-tabs-horizontal .responsive-tabs__list__item {
      padding: 14px 14px;
      transition: background 350ms ease; } }
  .red-light-tab .responsive-tabs__list__item:hover {
    background-color: #993113; }
  .mta-blue-tab .responsive-tabs__list__item:hover {
    background-color: #104975; }
  .yellow-tab .responsive-tabs__list__item:hover {
    background-color: #e9b721; }
  .responsive-tabs__list__item::before {
    display: none; }

.responsive-tabs__list__item--active,
.responsive-tabs__list__item--active:hover {
  color: white; }
  .red-light-tab .responsive-tabs__list__item--active, .red-light-tab
  .responsive-tabs__list__item--active:hover {
    background-color: #993113;
    border-top: 3px solid #993113; }
  .mta-blue-tab .responsive-tabs__list__item--active, .mta-blue-tab
  .responsive-tabs__list__item--active:hover {
    background-color: #104975;
    border-top: 3px solid #104975; }
  .yellow-tab .responsive-tabs__list__item--active, .yellow-tab
  .responsive-tabs__list__item--active:hover {
    background-color: #e9b721;
    border-top: 3px solid #e9b721; }
  @media only screen and (min-width: 48em) {
    .responsive-tabs-horizontal .responsive-tabs__list__item--active, .responsive-tabs-horizontal
    .responsive-tabs__list__item--active:hover {
      border-bottom: none;
      /*padding-top: em(11, (context: 19));*/
      position: relative;
      /*top: 1px;*/ }
    .responsive-tabs-vertical-left .responsive-tabs__list__item--active, .responsive-tabs-vertical-left
    .responsive-tabs__list__item--active:hover {
      border-left: none;
      padding: 6px 18px;
      border-top: none;
      border-right: none; }
    .responsive-tabs-vertical-right .responsive-tabs__list__item--active, .responsive-tabs-vertical-right
    .responsive-tabs__list__item--active:hover {
      border-left: none; } }

.responsive-tabs__heading:focus,
.responsive-tabs__list__item:focus,
.responsive-tabs__heading--active:focus,
.responsive-tabs__list__item--active:focus {
  outline: none !important; }

.search fieldset {
  position: relative; }

.search input {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
  padding-left: 2.5em; }

.search button, .search .btn-small {
  background-color: #993113;
  border: none;
  cursor: pointer;
  height: 2.5625em;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 2.75em; }
  .ie9 .search button, .ie9 .search .btn-small, .ie10 .search button, .ie10 .search .btn-small {
    background: #993113; }
  .ie9 main .search button, .ie9 main .search .btn-small, .ie10 main .search button, .ie10 main .search .btn-small {
    background: white; }
  .search button:hover, .search .btn-small:hover, .search button:focus, .search .btn-small:focus, .search button:active, .search .btn-small:active {
    background-color: #993113; }
  .search button svg, .search .btn-small svg {
    fill: white;
    padding:  0.75em 0.5em 0.75em 0em;
    height: 2.75em;
    width: 2.75em; }

@media only screen and (min-width: 64em) {
  .search {
    position: absolute;
    right: 6.5625em;
    top: -4em;
    max-width: 15.9375em; }
    .search.signed-in {
      right: 12.8125em; } }

.search-results .layout-primary, .search-results .layout-secondary {
  padding: 10px 0; }

.search-results .search {
  position: absolute;
  max-width: 34.375em;
  left: 0;
  right: 0;
  top: -2.5em; }
  .search-results .search input[type="text"] {
    border-color: #000;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8); }
    .search-results .search input[type="text"].valid {
      border-color: #000; }
  .search-results .search button, .search-results .search .btn-small {
    background-color: white; }
    .search-results .search button svg, .search-results .search .btn-small svg {
      fill: #000; }
  @media (320) {
    .search-results .search {
      max-width: 100%; } }

.search-summary {
  margin:  1.25em 0em;
  padding-top: 0.5em; }
  @media only screen and (min-width: 40em) {
    .search-summary {
      margin:  2.5em 0em; } }

.filters {
  background: #ececec;
  margin-bottom: 35px;
  padding: 10px; }
  .filters > ol {
    margin: 0; }
  .filters .checkbox-list {
    margin: 0; }
  .filters legend {
    display: none; }
  @media only screen and (min-width: 40em) {
    .filters {
      margin-bottom: 20px; } }

.filter-option {
  border-bottom: 1px solid #868686;
  margin-bottom: 0.5em;
  padding:  1em 0.5em; }
  .filter-option:last-child {
    border-bottom: none; }

.filter-title {
  color: #1480a6;
  cursor: pointer;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  text-transform: uppercase; }
  .filter-title svg {
    float: right;
    margin-right: 0; }

.closed .filter-details {
  display: none; }

.toggle-label {
  color: #085c79;
  cursor: pointer;
  transition: color 0.3s linear;
  text-decoration: none; }
  .toggle-label:hover, .toggle-label:active, .toggle-label:focus {
    color: #0d93c1; }

.timeline-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  color: white; }

.timeline-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both; }

#timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em; }

#timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d0d0d0; }

@media only screen and (min-width: 769px) {
  #timeline {
    margin-top: 3em;
    margin-bottom: 3em; }
  #timeline::before {
    left: 50%;
    margin-left: -2px; } }

.timeline-block {
  position: relative;
  margin: 2em 0; }

.timeline-block:after {
  content: "";
  display: table;
  clear: both; }

.timeline-block:first-child {
  margin-top: 0; }

.timeline-block:last-child {
  margin-bottom: 0; }

@media only screen and (min-width: 769px) {
  .timeline-block {
    margin: 4em 0; }
  .timeline-block:first-child {
    margin-top: 0; }
  .timeline-block:last-child {
    margin-bottom: 0; } }

.timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05); }

.timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px; }

.timeline-img.blue {
  background: #0060a9; }

.timeline-img.yellow {
  background: #e9b721; }

.timeline-img.red {
  background: #cd522e; }

@media only screen and (min-width: 769px) {
  .timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; }
  .cssanimations .timeline-img.is-hidden {
    visibility: hidden; }
  .cssanimations .timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s; } }

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5); }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2); }
  100% {
    -moz-transform: scale(1); } }

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.timeline-content {
  position: relative;
  margin-left: 60px;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed; }
  .timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #FFF; }
  .timeline-content.blue {
    background: #0060a9; }
    .timeline-content.blue::before {
      border-right-color: #0060a9; }
  .timeline-content.yellow {
    background: #e9b721; }
    .timeline-content.yellow::before {
      border-right-color: #e9b721; }
  .timeline-content.red {
    background: #cd522e; }
    .timeline-content.red::before {
      border-right-color: #cd522e; }

.timeline-content:after {
  content: "";
  display: table;
  clear: both; }

.timeline-content h2 {
  color: white;
  margin-bottom: 0px; }

.timeline-content p, .timeline-content .btn-secondary-alt, .timeline-content .date {
  font-size: 13px;
  font-size: 0.8125rem; }

.timeline-content .btn-secondary-alt, .timeline-content .date {
  display: inline-block; }

.timeline-content p {
  margin: 1em 0;
  line-height: 1.6; }

.timeline-content .btn-secondary-alt {
  float: right; }

.timeline-content .date {
  float: left;
  padding: .8em 0;
  color: #676562; }

@media only screen and (min-width: 768px) {
  .timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem; }
  .timeline-content p {
    font-size: 16px;
    font-size: 1rem; }
  .timeline-content .btn-secondary-alt, .timeline-content .date {
    font-size: 14px;
    font-size: 0.875rem; } }

@media only screen and (max-width: 769px) {
  .timeline-content .date {
    color: white; } }

@media only screen and (min-width: 769px) {
  .timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%; }
    .timeline-content.small {
      width: 41%; }
    .timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent; }
    .timeline-content.blue::before {
      border-color: transparent;
      border-left-color: #0060a9; }
    .timeline-content.yellow::before {
      border-color: transparent;
      border-left-color: #e9b721; }
    .timeline-content.red::before {
      border-color: transparent;
      border-left-color: #cd522e; }
  .timeline-content .btn-secondary-alt {
    float: left; }
  .timeline-content .date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem; }
    .timeline-content .date.small {
      left: 143%; }
  .timeline-block:nth-child(even) .timeline-content {
    float: right; }
    .timeline-block:nth-child(even) .timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent; }
    .timeline-block:nth-child(even) .timeline-content.blue::before {
      border-right-color: #0060a9; }
    .timeline-block:nth-child(even) .timeline-content.yellow::before {
      border-right-color: #e9b721; }
    .timeline-block:nth-child(even) .timeline-content.red::before {
      border-right-color: #cd522e; }
  .timeline-block:nth-child(even) .timeline-content .btn-secondary-alt {
    float: right; }
  .timeline-block:nth-child(even) .timeline-content .date {
    left: auto;
    right: 122%;
    text-align: right; }
    .timeline-block:nth-child(even) .timeline-content .date.small {
      right: 143%; } }

.color-trio {
  padding: 0; }
  @media only screen and (min-width: 40em) {
    .color-trio {
      padding-bottom: 1.25em; } }
  @media only screen and (min-width: 48em) {
    .color-trio {
      padding-bottom: 2.1875em; } }
  @media only screen and (min-width: 87.5em) {
    .color-trio {
      padding:  0em 0em 2.1875em; } }
  .color-trio .three-up:nth-child(n+2) {
    margin-top: 2px; }

.bg-color-tile {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white; }
  @media only screen and (min-width: 40em) {
    .bg-color-tile {
      display: flex; } }
  @media only screen and (min-width: 48em) {
    .bg-color-tile {
      background-image: none !important; } }
  .bg-color-tile img {
    display: none; }
    @media only screen and (min-width: 48em) {
      .bg-color-tile img {
        display: block; } }
  .bg-color-tile .content-wrap {
    padding:  2.5em 1.25em; }
    @media only screen and (min-width: 40em) {
      .bg-color-tile .content-wrap {
        display: block;
        padding: 1.25em; } }
    @media only screen and (min-width: 76.25em) {
      .bg-color-tile .content-wrap {
        padding: 1.875em; } }
  .bg-color-tile .p-name {
    color: white; }
  .bg-color-tile .p-summary {
    display: none; }
    @media only screen and (min-width: 48em) {
      .bg-color-tile .p-summary {
        display: block; } }
  .bg-color-tile.bg-blue .content-wrap {
    background-color: rgba(16, 73, 117, 0.6); }
    @media only screen and (min-width: 48em) {
      .bg-color-tile.bg-blue .content-wrap {
        background-color: transparent; } }
  .bg-color-tile.bg-red-light .content-wrap {
    background-color: rgba(205, 82, 46, 0.6); }
    @media only screen and (min-width: 48em) {
      .bg-color-tile.bg-red-light .content-wrap {
        background-color: transparent; } }
  .bg-color-tile.bg-teal .content-wrap {
    background-color: rgba(20, 128, 166, 0.6); }
    @media only screen and (min-width: 48em) {
      .bg-color-tile.bg-teal .content-wrap {
        background-color: transparent; } }
  .bg-color-tile.bg-yellow .content-wrap {
    background-color: rgba(233, 183, 33, 0.6); }
    @media only screen and (min-width: 48em) {
      .bg-color-tile.bg-yellow .content-wrap {
        color: #3f3f3f;
        background-color: transparent; } }
  @media only screen and (min-width: 48em) {
    .bg-color-tile.bg-yellow {
      color: #232323; } }
  .bg-color-tile.bg-yellow figcaption {
    background-color: rgba(233, 183, 33, 0.6); }
    @media only screen and (min-width: 48em) {
      .bg-color-tile.bg-yellow figcaption {
        background-color: transparent; } }

.tile-block {
  /*border-bottom: 1px solid transparent;*/
  padding: 1.25em;
  /*&:hover,
	&:active,
	&:focus {
		border-color: color(greyDark);
	}*/
  /*h5 {
		@extend a;
		
	}*/ }
  .tile-block .p-summary {
    color: #232323; }
    .tile-block .p-summary:hover, .tile-block .p-summary:active, .tile-block .p-summary:focus {
      color: #232323; }
    .tile-block .p-summary p {
      margin-bottom: 0; }

.no-bg-color-tile.h-entry {
  padding:  1.25em 0em; }
  @media only screen and (min-width: 48em) {
    .no-bg-color-tile.h-entry {
      padding: 0; } }

@media only screen and (min-width: 30em) {
  .no-bg-color-tile img {
    width: 45%; } }

@media only screen and (min-width: 40em) {
  .no-bg-color-tile img {
    width: 100%; } }

@media only screen and (min-width: 60em) {
  .no-bg-color-tile img {
    width: 45%; } }

@media only screen and (min-width: 30em) {
  .no-bg-color-tile .content-wrap {
    float: right;
    width: 50%; } }

@media only screen and (min-width: 40em) {
  .no-bg-color-tile .content-wrap {
    float: none;
    width: 100%; } }

@media only screen and (min-width: 60em) {
  .no-bg-color-tile .content-wrap {
    float: right;
    width: 50%; } }

.txt-red {
  color: #993113; }

/*@import "tooltips";*/
.events {
  border-top: 1px solid #d0d0d0;
  margin-top: 20px !important;
  padding-top: 20px; }
  @media only screen and (min-width: 40em) {
    .events {
      border-top: none;
      border-left: 1px solid #d0d0d0;
      margin-top: 0 !important;
      padding: 0 0 0 20px; } }

#trumba.spud.0 {
  margin-left: -4px; }

/*.trumba {
	background:color(greyPale);
	padding:em($columnPadding);
	
	table {
		border-collapse:collapse;
		width:100%;
	}
	
	tbody {
		@include font-size(12);
		th, td {
			background:transparent;
			border:none;
			display:table-cell;
			height:em(32, (context: 12));
			padding:0;
			vertical-align:middle;
			width:14.28571428571429%;
		}
	}
}
.twMiniCalTable {
	background:transparent !important;
	border:none !important;
	th, td {
		text-align:center;
	}
}
.twMiniCalTitle {
	padding-bottom:$columnPadding * 1.5;
}
.twMiniCalMonthLabel {
	@include font-size(16);
	font-weight: 700;
	text-align:right;
	text-transform:uppercase;
}
.twMiniCalMonthPager {
	text-align:right;
	a {
		margin-left: $columnPadding;
	}
}
.twMiniCalWeek {
	abbr {
		border:none;
	}
}


.twMiniCalEventDay, 
.twMiniCalToday {
	position:relative;
	
	a {
		display:inline-block;
		height:initial;
		padding:em(6 8);
	}
}

.twMiniCalEventDay {
	a {
		&::after {
			border:2px solid color(teal);
			border-radius:50%;
			content: "";
			display: block;
			height:em(36);
			margin-top:em(-32);
			width:em(36);
		}
	}	
}
.twMiniCalToday {
	.trumba & {
		
	}
	
	a {
		background: color(teal);
		border-radius:50%;
		color:white;
		
		&:hover,
		&:active {
			text-decoration: underline;
		}		
	}
}*/
.event-schedule li {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #d0d0d0;
  margin-top: 0.5em; }

.event-schedule .dt-start {
  color: #868686;
  display: block; }
  @media only screen and (min-width: 30em) {
    .event-schedule .dt-start {
      display: inline-block;
      float: left;
      width: 15%; } }
  @media only screen and (min-width: 40em) {
    .event-schedule .dt-start {
      width: 25%; } }

@media only screen and (min-width: 30em) {
  .event-schedule .p-name {
    display: block;
    padding-left: 20%; } }

@media only screen and (min-width: 40em) {
  .event-schedule .p-name {
    padding-left: 30%; } }

.accessibility {
  height: 0;
  left: -999em;
  line-height: 0;
  position: absolute; }

.browser-alert {
  background-color: #993113;
  color: white;
  font-size: 24px;
  font-size: 1.5rem;
  padding: 1em;
  text-align: center; }
  .browser-alert a {
    color: #d0d0d0; }

.is-sticky {
  background: white;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07); }
  @media only screen and (min-width: 48em) {
    .is-sticky {
      left: 0;
      padding: 0 20px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10; }
      .is-sticky .share {
        background: white;
        float: left;
        margin:  0em 1.25em 0em 0em;
        padding: 10px 0 3px; } }

.sticky-title {
  display: none; }
  .is-sticky .sticky-title {
    display: block; }

.img-align-center {
  display: block;
  margin: 0 auto; }

.img-full-width {
  display: block;
  width: 100%; }

.map-container {
  position: relative; }
  .map-container::before {
    content: '';
    display: block;
    padding-top: 56.25%; }
  .map-container img {
    max-width: none; }

.video-container {
  position: relative; }
  .video-container::before {
    content: '';
    display: block;
    padding-top: 56.25%; }

.inner-wrap {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px; }

.vertical-padding {
  padding:  1.875em 1.25em; }
  @media only screen and (min-width: 40em) {
    .vertical-padding {
      padding:  3.125em 1.875em; } }

.align-center {
  text-align: center; }

@media only screen and (min-width: 30em) {
  .float-left,
  .float-right {
    max-width: 45%; } }

@media only screen and (min-width: 30em) {
  .float-left {
    float: left;
    margin:  0em 1.25em 0.5em 0em; } }

@media only screen and (min-width: 30em) {
  .float-right {
    float: right;
    margin:  0em 0em 0.5em 1.25em; } }

.inline {
  display: inline-block; }

.block {
  clear: both;
  display: block; }

.no-padding {
  overflow: hidden;
  padding: 0; }

.no-side-padding {
  padding:  1.25em 0em; }
  @media only screen and (min-width: 48em) {
    .no-side-padding {
      padding: 35px 0; } }

.padding-bottom {
  padding-bottom: 20px; }
  @media only screen and (min-width: 48em) {
    .padding-bottom {
      padding-bottom: 35px; } }

.no-vertical-padding-mobile {
  padding-top: 0;
  padding-bottom: 0; }
  @media only screen and (min-width: 40em) {
    .no-vertical-padding-mobile {
      padding: 20px 0; } }
  @media only screen and (min-width: 48em) {
    .no-vertical-padding-mobile {
      padding: 35px 0; } }

.border-top, .search-summary {
  border-top: 1px solid #d0d0d0; }

.border-bottom, .media, .list-divider li {
  border-bottom: 1px solid #d0d0d0; }

.no-border {
  border: none; }

.rotate-device {
  display: none; }
  @media only screen and (max-width: 30em) and (orientation: portrait) {
    .rotate-device {
      display: block; } }

.valign {
  display: table;
  width: 100%; }
  .valign > div {
    display: table-cell;
    vertical-align: middle; }
  .valign > article {
    display: table-cell;
    vertical-align: middle; }
  .valign .column, .valign .columns {
    float: none; }

.ee-fieldeditor {
  font-size: 13px;
  font-size: 0.8125rem;
  color: white;
  display: table;
  margin: 0 auto;
  text-decoration: underline; }

.badge-indent a.ee-fieldeditor,
.xe-link a.ee-fieldeditor,
.bg-white a.ee-fieldeditor,
.bg-grey-pale a.ee-fieldeditor,
footer a.ee-fieldeditor,
.responsive-tabs-wrapper a.ee-fieldeditor {
  color: black; }

.xe-no-preview {
  height: 400px;
  background-color: #BBB;
  text-align: center;
  display: block;
  line-height: 400px;
  font-size: 24pt; }

.xe-secondary-content {
  margin: auto;
  width: 20%;
  padding: 15px; }

/* Slider */
/* Arrows */
/* Dots */
.slider {
  width: 100%;
  margin: 20px auto; }

.slick-slide {
  margin: 0px;
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: none;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  left: -25px; }

.slick-prev:before {
  color: black;
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '←'; }

.slick-prev:hover {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before {
  opacity: 1; }

.slick-prev:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:focus:before {
  opacity: 1; }

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: none;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  right: -25px; }

.slick-next:before {
  color: black;
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '→'; }

.slick-next:hover {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-next:hover:before {
  opacity: 1; }

.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-next:focus:before {
  opacity: 1; }

.slick-active {
  opacity: 1; }

.slick-current {
  opacity: 1; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  display: table;
  content: '';
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-loading .slick-slide {
  visibility: hidden; }

[dir='rtl'] .slick-slide {
  float: right; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev.slick-disabled:before {
  opacity: 0.25; }

.slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button, .slick-dots li .btn-small {
  font-size: 0;
  line-height: 0;
  display: none;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover, .slick-dots li .btn-small:hover {
  outline: none; }

.slick-dots li button:hover:before, .slick-dots li .btn-small:hover:before {
  opacity: 1; }

.slick-dots li button:focus, .slick-dots li .btn-small:focus {
  outline: none; }

.slick-dots li button:focus:before, .slick-dots li .btn-small:focus:before {
  opacity: 1; }

.slick-dots li button:before, .slick-dots li .btn-small:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before, .slick-dots li.slick-active .btn-small:before {
  opacity: 0.75;
  color: black; }

/* ===== 2 Slider ===== */
.slider .slide-container-2 {
  height: 30em; }

.slick-slide .slide-container-2 img {
  width: 100%;
  object-fit: cover;
  height: 100%; }

.layer2 {
  position: absolute;
  z-index: 2;
  background-color: white;
  width: 30%;
  height: 100%;
  opacity: 0.7;
  color: #3f3f3f; }

.layer2.no-fade {
  width: 5%; }

.haku {
  z-index: 2;
  font-size: 1.5em;
  text-align: center;
  padding: 1em 0.5em;
  line-height: 1.5em;
  min-height: 100%;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; }

.citation {
  font-size: 0.6em;
  line-height: 1em; }

/* ===== 4 Slider ===== */
.slider .slide-container-4 {
  /* height: 30em; */
  display: flex !important;
  flex-direction: column-reverse; }

.slick-slide .slide-container-4 img {
  order: 2;
  width: 100%;
  height: 10em;
  object-fit: cover; }

.layer-4 {
  width: 100%; }

.haku-4 {
  position: relative;
  width: 100%;
  padding: 0.8em 0.3em;
  font-size: 1.3em;
  line-height: 1.4em;
  vertical-align: center;
  text-align: center; }

@media only screen and (max-width: 768px) {
  .layer2.no-fade {
    width: 7%; }
  .layer2, .layer-4 {
    width: 13rem; }
  .haku, .haku-4 {
    padding: 0.7em 0.2em;
    font-size: 1.2em;
    line-height: 1.3em; }
  .haku-4 {
    position: relative;
    width: 100%;
    padding: 0.8em 0.3em;
    font-size: 1.3em;
    line-height: 1.4em;
    vertical-align: center; }
  .citation {
    font-size: 0.6em;
    line-height: 1.2em; } }

@media only screen and (max-width: 480px) {
  .layer2.no-fade {
    width: 100%; }
  .mobile-flex.slide-container-2 {
    height: unset; }
  .mobile-flex, .slide-container-2, .slide-container-4 {
    display: flex !important;
    flex-direction: column-reverse; }
  .slick-slide .slide-container-2 img {
    height: 18em;
    order: 2; }
  .slick-slide .slide-container-4 img {
    height: 18em;
    order: 2; }
  .slick-slide .slide-container-2 a {
    order: 2; }
  .slick-slide .slide-container-4 a {
    order: 2; }
  .layer2, .layer-4 {
    position: relative;
    width: 100%;
    order: 1; }
  .haku, .haku-4 {
    position: relative;
    width: 100%;
    padding: 0.8em 0.3em;
    font-size: 1.3em;
    line-height: 1.4em;
    vertical-align: center; }
  .citation {
    font-size: 0.6em;
    line-height: 1.2em; } }

.mobilize {
  background: none;
  color: #5b5b5b;
  min-height: 384px;
  padding: 20px 20px 20px 20px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
  /* Position tooltip above the element */
  /* Triangle hack to make tooltip look like a speech bubble */ }
  .mobilize.white-brdr {
    border: 1px solid #FFF;
    border-radius: 5px; }
  .mobilize.mta-red-brdr {
    border: 1px solid #eb0029;
    border-radius: 5px; }
  .mobilize.mta-red-bg {
    background-color: #eb0029;
    color: #fff; }
    .mobilize.mta-red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.mta-red-bg a:hover, .mobilize.mta-red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.mta-red-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.mta-red-bg h3, .mobilize.mta-red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.light-red-brdr {
    border: 1px solid #CD522E;
    border-radius: 5px; }
  .mobilize.light-red-bg {
    background-color: #CD522E;
    color: #fff; }
    .mobilize.light-red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.light-red-bg a:hover, .mobilize.light-red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.light-red-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.light-red-bg h3, .mobilize.light-red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.red-brdr {
    border: 1px solid #993113;
    border-radius: 5px; }
  .mobilize.red-bg {
    background-color: #993113;
    color: #fff; }
    .mobilize.red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.red-bg a:hover, .mobilize.red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.red-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.red-bg h3, .mobilize.red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.blue-brdr {
    border: 1px solid #104975;
    border-radius: 5px; }
  .mobilize.blue-bg {
    background-color: #104975;
    color: #fff; }
    .mobilize.blue-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.blue-bg a:hover, .mobilize.blue-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.blue-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.blue-bg h3, .mobilize.blue-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.mta-blue-brdr {
    border: 1px solid #0060a9;
    border-radius: 5px; }
  .mobilize.mta-blue-bg {
    background-color: #0060a9;
    color: #fff; }
    .mobilize.mta-blue-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.mta-blue-bg a:hover, .mobilize.mta-blue-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.mta-blue-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.mta-blue-bg h3, .mobilize.mta-blue-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.dark-teal-brdr {
    border: 1px solid #085c79;
    border-radius: 5px; }
  .mobilize.dark-teal-bg {
    background-color: #085c79;
    color: #fff; }
    .mobilize.dark-teal-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .mobilize.dark-teal-bg a:hover, .mobilize.dark-teal-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .mobilize.dark-teal-bg .mobilize-event .event-date {
      color: #ececec; }
    .mobilize.dark-teal-bg h3, .mobilize.dark-teal-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .mobilize.two-up {
    margin-right: 0;
    margin-left: 0; }
    .mobilize.two-up.margin-right {
      margin-right: 3.8%; }
  .mobilize h3, .mobilize h4 {
    font-family: effra,Arial,sans-serif;
    color: #5b5b5b;
    font-weight: 400; }
  .mobilize a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #104975; }
  .mobilize a:hover, .mobilize a:focus {
    text-decoration: underline;
    color: #1480a6; }
  .mobilize .mobilize-event.row {
    padding: 0px; }
  .mobilize .mobilize-event .event-date {
    color: #000000;
    font-weight: 700;
    font-family: 'Open Sans', helvetica, arial, sans-serif; }
  .mobilize .mobilize-event .event-title {
    color: #104975; }
  .mobilize [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer; }
  .mobilize [data-tooltip]:before,
  .mobilize [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none; }
  .mobilize [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: rgba(51, 51, 51, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: left;
    font-size: 14px;
    line-height: 1.2; }
  .mobilize [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid rgba(51, 51, 51, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0; }
  .mobilize [data-tooltip]:hover:before,
  .mobilize [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1; }
  .mobilize.full-width {
    font-size: .95rem; }

.youtube-section.white-brdr {
  border: 1px solid #FFF;
  border-radius: 5px; }

.youtube-section.mta-red-brdr {
  border: 1px solid #eb0029;
  border-radius: 5px; }

.youtube-section.mta-red-bg {
  background-color: #eb0029;
  color: #fff; }
  .youtube-section.mta-red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.mta-red-bg a:hover, .youtube-section.mta-red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.mta-red-bg h3, .youtube-section.mta-red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.light-red-brdr {
  border: 1px solid #CD522E;
  border-radius: 5px; }

.youtube-section.light-red-bg {
  background-color: #CD522E;
  color: #fff; }
  .youtube-section.light-red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.light-red-bg a:hover, .youtube-section.light-red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.light-red-bg h3, .youtube-section.light-red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.red-brdr {
  border: 1px solid #993113;
  border-radius: 5px; }

.youtube-section.red-bg {
  background-color: #993113;
  color: #fff; }
  .youtube-section.red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.red-bg a:hover, .youtube-section.red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.red-bg h3, .youtube-section.red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.blue-brdr {
  border: 1px solid #104975;
  border-radius: 5px; }

.youtube-section.blue-bg {
  background-color: #104975;
  color: #fff; }
  .youtube-section.blue-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.blue-bg a:hover, .youtube-section.blue-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.blue-bg h3, .youtube-section.blue-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.mta-blue-brdr {
  border: 1px solid #0060a9;
  border-radius: 5px; }

.youtube-section.mta-blue-bg {
  background-color: #0060a9;
  color: #fff; }
  .youtube-section.mta-blue-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.mta-blue-bg a:hover, .youtube-section.mta-blue-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.mta-blue-bg h3, .youtube-section.mta-blue-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.dark-teal-brdr {
  border: 1px solid #085c79;
  border-radius: 5px; }

.youtube-section.dark-teal-bg {
  background-color: #085c79;
  color: #fff; }
  .youtube-section.dark-teal-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .youtube-section.dark-teal-bg a:hover, .youtube-section.dark-teal-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .youtube-section.dark-teal-bg h3, .youtube-section.dark-teal-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }

.youtube-section.two-up {
  margin-right: 0;
  margin-left: 0; }
  .youtube-section.two-up.margin-right {
    margin-right: 3.8%; }

.youtube-section h3, .youtube-section h4 {
  font-family: effra,Arial,sans-serif;
  color: #5b5b5b;
  font-weight: 400; }

.youtube-section a {
  text-decoration: none;
  font-weight: normal;
  white-space: normal;
  color: #104975; }

.youtube-section a:hover, .youtube-section a:focus {
  text-decoration: underline;
  color: #1480a6; }

.youtube-section .image:hover {
  opacity: 0.3; }

.youtube-section .all-videos-link {
  padding-left: 2%; }

.youtube-section .youtube-video .title {
  font-weight: 600; }

.youtube-section .video-row {
  padding-top: 0;
  padding-bottom: 0; }

.cvent {
  background: none;
  color: #5b5b5b;
  min-height: 100px;
  padding: 20px 20px 20px 20px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
  /*.event-title a:link {
		display: block;
		color: rgb(255 255 255 / 100%);
		border: 1px #fff dotted;
		padding: 5px 10px;
		background-color: #828282;
		border-radius: 8px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
	}*/
  /* Position tooltip above the element */
  /* Triangle hack to make tooltip look like a speech bubble */ }
  .cvent .show-description {
    border: 1px solid white;
    padding: 8px; }
  .cvent .hide-description {
    border: 1px solid white;
    padding: 8px; }
  .cvent .event-title a:hover {
    text-decoration: underline;
    color: #104975;
    background-color: #ffffff;
    box-shadow: none;
    text-decoration: none;
    border: 1px #fff solid;
    color: #fff;
    text-shadow: 1px 1px 2px #000000; }
  .cvent .row {
    padding: 0 0 .5rem 0; }
  .cvent.white-brdr {
    border: 1px solid #FFF;
    border-radius: 5px; }
  .cvent.mta-red-brdr {
    border: 1px solid #eb0029;
    border-radius: 5px; }
  .cvent.mta-red-bg {
    background-color: #eb0029;
    color: #fff; }
    .cvent.mta-red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.mta-red-bg a:hover, .cvent.mta-red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.mta-red-bg .session .event-date {
      color: #ececec; }
    .cvent.mta-red-bg h3, .cvent.mta-red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.light-red-brdr {
    border: 1px solid #CD522E;
    border-radius: 5px; }
  .cvent.light-red-bg {
    background-color: #CD522E;
    color: #fff; }
    .cvent.light-red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.light-red-bg a:hover, .cvent.light-red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.light-red-bg .session .event-date {
      color: #ececec; }
    .cvent.light-red-bg h3, .cvent.light-red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.red-brdr {
    border: 1px solid #993113;
    border-radius: 5px; }
  .cvent.red-bg {
    background-color: #993113;
    color: #fff; }
    .cvent.red-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.red-bg a:hover, .cvent.red-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.red-bg .session .event-date {
      color: #ececec; }
    .cvent.red-bg h3, .cvent.red-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.blue-brdr {
    border: 1px solid #104975;
    border-radius: 5px; }
  .cvent.blue-bg {
    background-color: #104975;
    color: #fff; }
    .cvent.blue-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.blue-bg a:hover, .cvent.blue-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.blue-bg .session .event-date {
      color: #ececec; }
    .cvent.blue-bg h3, .cvent.blue-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.mta-blue-brdr {
    border: 1px solid #0060a9;
    border-radius: 5px; }
  .cvent.mta-blue-bg {
    background-color: #0060a9;
    color: #fff; }
    .cvent.mta-blue-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.mta-blue-bg a:hover, .cvent.mta-blue-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.mta-blue-bg .session .event-date {
      color: #ececec; }
    .cvent.mta-blue-bg h3, .cvent.mta-blue-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.dark-teal-brdr {
    border: 1px solid #085c79;
    border-radius: 5px; }
  .cvent.dark-teal-bg {
    background-color: #085c79;
    color: #fff; }
    .cvent.dark-teal-bg a {
      text-decoration: none;
      font-weight: normal;
      white-space: normal;
      color: #FFF; }
    .cvent.dark-teal-bg a:hover, .cvent.dark-teal-bg a:focus {
      text-decoration: underline;
      color: #FFF; }
    .cvent.dark-teal-bg .session .event-date {
      color: #ececec; }
    .cvent.dark-teal-bg h3, .cvent.dark-teal-bg h4 {
      font-family: effra,Arial,sans-serif;
      color: #FFF;
      font-weight: 400; }
  .cvent.two-up {
    margin-right: 0;
    margin-left: 0; }
    .cvent.two-up.margin-right {
      margin-right: 3%; }
  .cvent h3, .cvent h4 {
    font-family: effra,Arial,sans-serif;
    color: #5b5b5b;
    font-weight: 400; }
  .cvent a {
    text-decoration: underline;
    font-weight: normal;
    white-space: normal;
    color: #104975; }
  .cvent a:hover, .cvent a:focus {
    text-decoration: underline;
    color: #1480a6; }
  .cvent .session {
    border-bottom: .5px solid #fff; }
    .cvent .session .event-date {
      color: #000000;
      font-weight: 700;
      font-family: 'Open Sans', helvetica, arial, sans-serif; }
    .cvent .session .event-title {
      color: #104975; }
    .cvent .session .wait-list {
      text-align: center;
      border: 1px solid #fff;
      background: #0060a9;
      padding: .5rem 0;
      background: #0060a9;
      color: #fff; }
  .cvent [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer; }
  .cvent [data-tooltip]:before,
  .cvent [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none; }
  .cvent [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: rgba(51, 51, 51, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: left;
    font-size: 14px;
    line-height: 1.2; }
  .cvent [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid rgba(51, 51, 51, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0; }
  .cvent [data-tooltip]:hover:before,
  .cvent [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1; }
  .cvent.full-width {
    font-size: .95rem; }

.sidebar-list-component .sidebar-list-horizontal {
  padding: 0;
  text-align: center; }
  .sidebar-list-component .sidebar-list-horizontal li {
    list-style: none;
    clear: both;
    margin: 5px 5px 0 0;
    justify-content: flex-start;
    color: #3f3f3f;
    display: inline-block; }
    .sidebar-list-component .sidebar-list-horizontal li::before {
      content: none; }
  .sidebar-list-component .sidebar-list-horizontal .list-item-primary {
    font-weight: 700;
    padding: 10px;
    border: 1px dotted #104975; }
    .sidebar-list-component .sidebar-list-horizontal .list-item-primary:hover {
      background: #ececec; }
      .sidebar-list-component .sidebar-list-horizontal .list-item-primary:hover .list-children-content {
        display: block; }
  .sidebar-list-component .sidebar-list-horizontal .list-item-secondary {
    font-weight: 500;
    display: block; }
  .sidebar-list-component .sidebar-list-horizontal .list-children-content {
    background: #ececec;
    display: none;
    padding: 10px;
    z-index: 1;
    position: absolute;
    margin: 0 0 0 -10px; }

.sidebar-list-component .sidebar-list-vertical {
  padding: 0; }
  .sidebar-list-component .sidebar-list-vertical li {
    list-style: none;
    clear: both;
    margin: 5px 5px 0 0;
    padding: 10px;
    width: 100%;
    justify-content: flex-start;
    color: #3f3f3f; }
    .sidebar-list-component .sidebar-list-vertical li::before {
      content: none; }
  .sidebar-list-component .sidebar-list-vertical .list-item-primary {
    font-weight: 700;
    border-bottom: 1px dotted #104975; }
  .sidebar-list-component .sidebar-list-vertical .list-item-secondary {
    font-weight: 500;
    padding: 0;
    text-decoration: underline dotted;
    text-underline-offset: 5px; }
    .sidebar-list-component .sidebar-list-vertical .list-item-secondary::before {
      content: none; }

/*.sidebar-list-component {
	li {
		display: flex;
		justify-content: center;
		margin-top: 0px;
	}
}

.list-plain li {
	border: 1px dotted #104975;
	float: left;
	margin: 10px;
	padding: 10px;
	font-size: .85rem;
}

 l {
	list-style: none;
	clear: both;
	border: 1px dotted #104975;
	margin: 5px 5px 0 0;
	padding: 10px;
	font-size: .85rem;
	width: 100%;
	justify-content: flex-start;
}

ul li::before {
	color: #993113;
	content: "";
}

.layout-centered .two-up {
	width: 100%;
}

@media only screen and (max-width: 40em) {
	.list-plain {
		display: block;
		padding-left: 0;
		margin-top: none;
	}
	.list-plain li {
		clear: both;
		width: 100%;
		text-align: center;
		margin: 5px 10px 0 0;
	}
}*/
.documents-container table, .documents-container th, .documents-container tr {
  color: unset; }

.documents-container tr:nth-child(2n) > td {
  background: unset; }

.documents-container.white-brdr {
  border: 1px solid #FFF;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.mta-red-brdr {
  border: 1px solid #eb0029;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.mta-red-bg {
  background-color: #eb0029;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.mta-red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.mta-red-bg a:hover, .documents-container.mta-red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.mta-red-bg .session .event-date {
    color: #ececec; }
  .documents-container.mta-red-bg h3, .documents-container.mta-red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.mta-red-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.mta-red-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.mta-red-bg [type="button"]:hover {
    color: #085c79; }

.documents-container.light-red-brdr {
  border: 1px solid #CD522E;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.light-red-bg {
  background-color: #CD522E;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.light-red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.light-red-bg a:hover, .documents-container.light-red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.light-red-bg .session .event-date {
    color: #ececec; }
  .documents-container.light-red-bg h3, .documents-container.light-red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.light-red-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.light-red-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.light-red-bg [type="button"]:hover {
    color: #085c79; }

.documents-container.red-brdr {
  border: 1px solid #993113;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.red-bg {
  background-color: #993113;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.red-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.red-bg a:hover, .documents-container.red-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.red-bg .session .event-date {
    color: #ececec; }
  .documents-container.red-bg h3, .documents-container.red-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.red-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.red-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.red-bg [type="button"]:hover {
    color: #085c79; }

.documents-container.blue-brdr {
  border: 1px solid #104975;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.blue-bg {
  background-color: #104975;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.blue-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.blue-bg a:hover, .documents-container.blue-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.blue-bg .session .event-date {
    color: #ececec; }
  .documents-container.blue-bg h3, .documents-container.blue-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.blue-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.blue-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.blue-bg [type="button"]:hover {
    color: #085c79; }

.documents-container.mta-blue-brdr {
  border: 1px solid #0060a9;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.mta-blue-bg {
  background-color: #0060a9;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.mta-blue-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.mta-blue-bg a:hover, .documents-container.mta-blue-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.mta-blue-bg .session .event-date {
    color: #ececec; }
  .documents-container.mta-blue-bg h3, .documents-container.mta-blue-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.mta-blue-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.mta-blue-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.mta-blue-bg [type="button"]:hover {
    color: #085c79; }

.documents-container.dark-teal-brdr {
  border: 1px solid #085c79;
  border-radius: 5px;
  padding: 2rem 0rem 2rem 2rem; }

.documents-container.dark-teal-bg {
  background-color: #085c79;
  color: #fff;
  padding: 2rem 0rem 2rem 2rem; }
  .documents-container.dark-teal-bg a {
    text-decoration: none;
    font-weight: normal;
    white-space: normal;
    color: #FFF; }
  .documents-container.dark-teal-bg a:hover, .documents-container.dark-teal-bg a:focus {
    text-decoration: underline;
    color: #FFF; }
  .documents-container.dark-teal-bg .session .event-date {
    color: #ececec; }
  .documents-container.dark-teal-bg h3, .documents-container.dark-teal-bg h4 {
    font-family: effra,Arial,sans-serif;
    color: #FFF;
    font-weight: 400; }
  .documents-container.dark-teal-bg #btnUpload {
    background-color: #FFF; }
  .documents-container.dark-teal-bg th {
    border-top: 1px solid #FFF; }
  .documents-container.dark-teal-bg [type="button"]:hover {
    color: #085c79; }

.documents-container h3, .documents-container h4 {
  font-family: effra,Arial,sans-serif;
  color: #5b5b5b;
  font-weight: 400; }

.documents-container a {
  text-decoration: underline;
  font-weight: normal;
  white-space: normal;
  color: #104975; }

.documents-container a:hover, .documents-container a:focus {
  text-decoration: underline;
  color: #1480a6; }
